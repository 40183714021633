import { UnpaidDetails, getUnpaidDetailsEndpoint } from '@cp-mx/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<UnpaidDetails, DefaultBusinessMarketApiErrorCode>({
    dataName: 'unpaidDetails',
    fetchCallback() {
        return CpDataApi.get(getUnpaidDetailsEndpoint()).then(response => response.data);
    },
});

export default reducer;
export const fetchUnpaidBalance = fetchData;
