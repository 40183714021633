import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

export const Countdown: React.FC<{
    component: React.ReactNode;
    incorrectCode: boolean;
    milliseconds: number;
    setStatusTimer: (count: number) => void;
    displayCounter: number;
}> = ({ component, incorrectCode, milliseconds, setStatusTimer, displayCounter }) => {
    const [timeLeft, setTimeLeft] = useState(milliseconds);
    const [timer, setTimer] = useState('');
    const [displayCounterPrev, setDisplayCounterPrev] = useState<number>(0);

    if (displayCounter !== 0 && displayCounter !== displayCounterPrev) {
        setTimeLeft(milliseconds);
        setDisplayCounterPrev(displayCounter);
    }

    const initialStatusTimer = useCallback(setStatusTimer, []);

    useEffect(() => {
        if (!timeLeft) return;

        const intervalId = setInterval(() => {
            const time = timeLeft - 1000;
            setTimer(moment.utc(time).format('mm:ss'));
            setTimeLeft(time);
            initialStatusTimer(time);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [initialStatusTimer, timeLeft]);

    const { t } = useTranslation('my-profile-contact-details');
    return (
        <span>
            {timeLeft > 0 ? (
                <>
                    {incorrectCode ? (
                        <>
                            {t('modal.errors.incorrect')}
                            {timer}
                            {t('modal.minutes')}
                        </>
                    ) : (
                        <>
                            <b className="u-text-brand">{t('modal.expires')}</b>
                            {timer}
                            {t('modal.minutes')}
                        </>
                    )}
                </>
            ) : (
                <>{component}</>
            )}
        </span>
    );
};
