import { useGetSimpleUpdateData } from '@cp-shared-8/frontend-integration';
import { NotificationsCenter } from '@cp-mx/common';
import { updateNotification } from './NotificationCenterSlice';
import { useNotificationsAllSelector } from './NotificationCenterSelector';

type UpdateNotificationCenterType = {
    updateNotificationCenter: (data: NotificationsCenter[]) => void;
    notificationCenterData: NotificationsCenter[];
};

export function useUpdateNotificationCenter(): UpdateNotificationCenterType {
    const {
        updateData,
        dataState: { data },
    } = useGetSimpleUpdateData(updateNotification, useNotificationsAllSelector);
    return {
        updateNotificationCenter: updateData,
        notificationCenterData: data as NotificationsCenter[],
    };
}
