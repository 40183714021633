import React from 'react';
import { useTranslation } from 'react-i18next';

import { ContextNotification, Notification } from '@vwfs-bronson/bronson-react';

export type ContextNotificationProps = {
    testId?: string;
    component?: string;
    visible: boolean;
    onCloseClick: () => void;
};

export const CtxNotification: React.FC<ContextNotificationProps> = ({ testId, component, visible, onCloseClick }) => {
    const { t } = useTranslation(component);
    return (
        <ContextNotification>
            <Notification
                testId={testId}
                status="error"
                title={t('error.noConnection.title')}
                visible={visible}
                onCloseClick={() => onCloseClick()}
            >
                {t('error.noConnection.text')}
            </Notification>
        </ContextNotification>
    );
};
