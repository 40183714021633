import { getModelCatalogEndpoint, Model } from '@cp-mx/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<Model>({
    contentName: 'model-catalog',
    contentEndpoint: getModelCatalogEndpoint,
});

export default reducer;
export const fetchModelCatalog = fetchContent;
