import React from 'react';
import { withLoadingHandler } from 'components/integration-wrapper';

import { GreetingUi } from './ui';
import { useGreeting } from './useGreeting';

const GreetingWithHandlers = withLoadingHandler(GreetingUi);

export const Greeting: React.FC = () => {
    const { data: greeting, isLoading } = useGreeting();

    return <GreetingWithHandlers isLoading={isLoading} greeting={greeting} />;
};
