import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { FormView } from './form-view/FormView';
import { useContract } from 'components/contracts';

export const Request: React.FC<{}> = () => {
    const { data: contracts, isLoading, loadingError } = useContract();

    const RequestWithHandlers = withLoadingAndNoConnectionHandler(FormView);

    return <RequestWithHandlers isLoading={isLoading} contracts={contracts} hasError={!!loadingError} />;
};
