import { NotificationSelector } from './NotificationSelector';
import { useDispatch, useSelector } from 'react-redux';

export const useNotification = () => {
    const dispatch = useDispatch();
    const notificationState = useSelector(NotificationSelector);

    return {
        notificationState,
        setNotification: (loyaltyProgramId: number) => {
            dispatch({ type: 'delete', data: [...notificationState, loyaltyProgramId] });
        },
    };
};
