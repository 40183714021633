import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormField } from '@vwfs-bronson/bronson-react';
import { RequestInitialValues } from '../initialValues';
import { FormikProps, useFormikContext } from 'formik';
import { CategoryDropDown } from '@cp-mx/common';
import { useCategorySelection } from './useCategorySelection';
import { CustomSelectUI, CustomSelectElement } from './UI';

export type CategorySelectionProps = {
    currentCategorySelection?: string;
    resetForm: Function;
    contractSelection: string;
};

export const CategorySelection: React.FC<CategorySelectionProps> = ({
    currentCategorySelection,
    resetForm,
    contractSelection,
}) => {
    const { initialValues }: FormikProps<RequestInitialValues> = useFormikContext();
    const { cmsContent: categorySelection } = useCategorySelection();
    const { t } = useTranslation('open-request');

    if (!categorySelection) {
        return null;
    }

    const setValueChange = (selectedCategory: string) => {
        if (selectedCategory !== currentCategorySelection) {
            resetForm({
                values: { ...initialValues, categorySelection: selectedCategory },
            });
        }
    };

    const filter =
        !contractSelection || contractSelection === 'Solicitud General'
            ? categorySelection.categories.filter((category: CategoryDropDown) => category.needContract !== 1)
            : categorySelection.categories.filter((category: CategoryDropDown) => category.needContract !== 2);

    const options: CustomSelectElement[] = categorySelection
        ? filter.map((category: CategoryDropDown) => {
              return {
                  optionKey: category.id,
                  returnValue: category.id,
                  displayValue: category.name,
                  content: <strong>{category.name}</strong>,
              };
          })
        : [];

    options.unshift({
        optionKey: 'no-category',
        returnValue: 'no-category',
        displayValue: t('fields.category.placeholder'),
        content: <strong>{t('fields.category.placeholder')}</strong>,
    });

    return (
        <FormField type="select" testId={'category-selection'}>
            <CustomSelectUI
                label={t('fields.category.label')}
                required
                dropdownElements={options}
                onChange={setValueChange}
                selectedKey={currentCategorySelection}
            />
        </FormField>
    );
};
