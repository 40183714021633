import * as Yup from 'yup';
import { TFunction } from 'i18next';

export const validationSchema = (t: TFunction) => {
    return Yup.object().shape({
        categorySelection: Yup.string().required(t('fields.category.placeholder')),
        inputField1: Yup.string()
            .required(t('fields.inputField1.errors.required'))
            .max(40, t('fields.inputField1.errors.max')),
        inputField2: Yup.string()
            .required(t('fields.inputField2.errors.required'))
            .max(1000, t('fields.inputField2.errors.max')),
    });
};
