import { FinancialDetails, getFinancialDetailsEndpoint, ProductName } from '@cp-mx/common';
import { DefaultMarketApiErrors } from '@cp-shared-8/common-utilities';
import {
    AbstractDataState,
    useGetContractBasedApiData,
    hasFetchedSuccessfully,
    AbstractContentState,
    useCmsContent,
} from '@cp-shared-8/frontend-integration';
import { useContractEspecific } from '../../useContracts';
import { useMemo } from 'react';
import { fetchFinancialDetails } from './FinancialDetailsSlice';
import { FinancialDetailsDataSelector, productNameCatalogContentSelector } from './FinancialDetailsContentSelector';
import { fetchProductNameCatalog } from './ProductNameSlice';

export function useFinancialDetails(contractId: string): AbstractDataState<FinancialDetails, DefaultMarketApiErrors> {
    const contractState = useContractEspecific(contractId);
    const financialDetailsState = useGetContractBasedApiData(
        contractId,
        fetchFinancialDetails,
        FinancialDetailsDataSelector,
        getFinancialDetailsEndpoint(contractState.data?.contractNumber ? contractState.data?.contractNumber : ''),
    );
    return useMemo(
        () => ({
            isLoading: contractState.isLoading || financialDetailsState.isLoading,
            hasReceivedResponse: contractState.loadingError
                ? contractState.hasReceivedResponse
                : contractState.hasReceivedResponse && financialDetailsState.hasReceivedResponse,
            failedLoadingAttempts: hasFetchedSuccessfully(contractState)
                ? financialDetailsState.failedLoadingAttempts
                : contractState.failedLoadingAttempts,
            data: financialDetailsState.data,
            loadingError: hasFetchedSuccessfully(contractState)
                ? financialDetailsState.loadingError
                : contractState.loadingError,
        }),
        [contractState, financialDetailsState],
    );
}
export function useProductNamesContent(): AbstractContentState<ProductName> {
    return useCmsContent(fetchProductNameCatalog, productNameCatalogContentSelector);
}
