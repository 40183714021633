import React from 'react';

export const UnauthorizedUi: React.FC<{ headline: string; text: string }> = unauthorizedContent => {
    return (
        <div className="c-hero-teaser__content-inner u-mv">
            <header className="c-hero-teaser__heading">
                <div className="c-hero-teaser__title">
                    <h1 className="c-hero-teaser__title-row">{unauthorizedContent.headline}</h1>
                </div>
                <div
                    className="c-hero-teaser__subtitle"
                    dangerouslySetInnerHTML={{ __html: unauthorizedContent.text }}
                />
            </header>
        </div>
    );
};
