import {
    DATE_FORMAT,
    getPersonRegistrationEndpoint,
    PersonRegistrationData,
    RegistrationError,
    RegistrationResult,
} from '@cp-mx/common';
import { WithDefaultCpIntegrationErrors, formatCpDate } from '@cp-shared-8/common-utilities';
import { CpDataApi } from 'cp-xhr';
import moment from 'moment';
import { PersonFormProps } from '../types';

export const handleSubmit = (
    formValues: PersonFormProps,
    successCallback: (registrationResult: RegistrationResult, registrationData: PersonFormProps) => void,
    errorCallback: (errorCode: WithDefaultCpIntegrationErrors<RegistrationError>, errorMessage: string) => void,
): void => {
    const { privateCustomerNumber, dateOfBirth } = formValues;
    const registrationData: PersonRegistrationData = {
        idCustomer: privateCustomerNumber,
        dateOfBirth: formatCpDate(dateOfBirth, DATE_FORMAT).toCpDate(),
    };
    CpDataApi.post(getPersonRegistrationEndpoint(), registrationData)
        .then(response => {
            const responseData: RegistrationResult = response.data;
            successCallback(responseData, formValues);
        })
        .catch(error => {
            const errorCode = error.response?.data?.code || RegistrationError.NETWORK_ERROR;
            const errorMessage = error.response?.data?.message;
            errorCallback(errorCode, errorMessage);
        });
};

export const getAge = (birthDate: Date) => {
    return moment().diff(birthDate, 'years');
};
