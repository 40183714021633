import React from 'react';

import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';

import { ContractDetailsUi } from './ui';
import { useContractDetails } from './useContractDetails';
import { Contract } from '@cp-mx/common';

const ContractDetailsWithHandlers = withLoadingAndNoConnectionHandler(ContractDetailsUi);

export const ContractDetails: React.FC<{ contract: Contract }> = ({ contract }) => {
    const { cmsContent: contractDetails, isLoading, loadingError } = useContractDetails();

    return (
        <>
            <ContractDetailsWithHandlers
                isLoading={isLoading}
                contract={contract}
                contractDetails={contractDetails}
                hasError={!!loadingError}
            />
        </>
    );
};
