import { SimplePage, getLegalNoticeEndpoint } from '@cp-mx/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<SimplePage>({
    contentName: 'legalNotice',
    contentEndpoint: getLegalNoticeEndpoint,
});

export default reducer;
export const fetchLegalNotice = fetchContent;
