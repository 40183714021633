import { Reducer } from 'redux';

export const notificationRenewalCloseReducer: Reducer<string[]> = (state = [], action) => {
    switch (action.type) {
        case 'delete':
            return action.data;
        case 'add':
            return action.data;
        default:
            return state;
    }
};
