export function getNotificationsByCustomerEndpoint(): string {
    return '/notifications';
}

export function updateSessionCounterEndpoint(): string {
    return '/notifications/counter';
}

export function updateRenewalCloseEndpoint(): string {
    return '/notifications/renewal/close';
}

export function getNotificationsCenterEndpoint(): string {
    return '/notifications/center';
}

export function patchNotificationsCenterReadEndpoint(notificationId: string): string {
    return `/notifications/center/read/${notificationId}`;
}
