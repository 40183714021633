import { useGetSimpleUpdateData } from '@cp-shared-8/frontend-integration';
import { ContactData } from '@cp-mx/common';
import { updateContactData } from './MyContactSlice';
import { MyContactSelector } from './MyContactSelector';

type UpdateContactType = {
    updateContactData: (data: ContactData) => void;
    contactData: ContactData;
};

export function useUpdateContactData(): UpdateContactType {
    const {
        updateData,
        dataState: { data },
    } = useGetSimpleUpdateData(updateContactData, MyContactSelector);
    return {
        updateContactData: updateData,
        contactData: data as ContactData,
    };
}
