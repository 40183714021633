import React from 'react';
import { useTranslation } from 'react-i18next';
import { Address } from '@cp-mx/common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';

export const AddressList: React.FC<{ address: Address }> = ({ address }) => {
    const { t } = useTranslation('my-profile-addresses-details');
    const { zipCode, region, city, countryCode } = address;

    const addressList: DefinitionListItem[] = [];

    zipCode &&
        addressList.push({
            label: t(`address.zip-code`),
            value: zipCode,
            testId: 'address-details-street',
        });

    city &&
        addressList.push({
            label: t(`address.city`),
            value: city,
            testId: 'address-details-postal-code',
        });

    region &&
        addressList.push({
            label: t(`address.region`),
            value: region,
            testId: 'address-details-city',
        });

    countryCode &&
        addressList.push({
            label: t(`address.country-code`),
            value: countryCode,
            testId: 'address-details-country',
        });

    return <DefinitionListHorizontal list={addressList} />;
};
