export function getLoyaltiesEndpoint(): string {
    return '/loyalty';
}

export function getCertificateEndpoint(loyaltyProgramId: string): string {
    return `/loyalty/certificate/${loyaltyProgramId}`;
}

export function getCertificateSessionCounterEndpoint(): string {
    return '/loyalty/certificate/sessions';
}
