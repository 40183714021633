import React, { useCallback, useEffect, useState } from 'react';
import { Input } from '@vwfs-bronson/bronson-react';

export const OtpInput: React.FC<{
    lengthCode: number;
    onHandleCode: (code: string) => void;
}> = ({ lengthCode, onHandleCode }) => {
    type Code = {
        [key: number]: string;
    };

    const [code, setCode] = useState<Code>({});

    const initialOnChange = useCallback(onHandleCode, []);

    useEffect(() => {
        const codeValue = Object.values(code).join('');
        initialOnChange(codeValue);
    }, [initialOnChange, code]);

    useEffect(() => {
        const note = document.querySelectorAll('[id^="otp-"]');
        if (note.length) {
            for (let i = 0; i < note.length; i++) {
                const element = note[i] as HTMLElement;
                element.style.border = 'none';
            }
        }
    }, []);

    const onHandleBlurOtp = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        const event = e.target as HTMLInputElement;
        const value = event.value;

        if (
            e.key === '0' ||
            e.key === '1' ||
            e.key === '2' ||
            e.key === '3' ||
            e.key === '4' ||
            e.key === '5' ||
            e.key === '6' ||
            e.key === '7' ||
            e.key === '8' ||
            e.key === '9'
        ) {
            const nextVal = document.getElementById(`otp-${index + 1}`);

            if (nextVal) {
                nextVal.focus();
            }
        }
        setCode(code => ({ ...code, [index]: value }));
    };

    const renderInputsCode = () => {
        const boxes: Array<JSX.Element> = [];
        for (let i = 0; i < lengthCode; i++) {
            if (i === lengthCode - 1) {
                boxes.push(
                    <div key={i} className="numInputWrapper" style={{ width: '42px', display: 'inline-block' }}>
                        <Input
                            id={`otp-${i}`}
                            type="tel"
                            maxLength={1}
                            onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => onHandleBlurOtp(e, i)}
                        />
                    </div>,
                );
            } else {
                boxes.push(
                    <span key={i}>
                        <div className="numInputWrapper" style={{ width: '42px', display: 'inline-block' }}>
                            <Input
                                id={`otp-${i}`}
                                type="tel"
                                maxLength={1}
                                onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => onHandleBlurOtp(e, i)}
                            />
                        </div>
                        <span> - </span>
                    </span>,
                );
            }
        }

        return boxes;
    };
    return <div id="opt-code">{renderInputsCode()}</div>;
};
