import React from 'react';
import { withLoadingHandler } from 'components/integration-wrapper';
import { MyProfileUi } from './ui';
import { useMyProfile } from './useMyProfile';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { useMyContact } from './useMyContact';

const MyProfileWithHandlers = withLoadingHandler(MyProfileUi);

export const MyProfile: React.FC = () => {
    const { data, isLoading } = useMyProfile();
    const { data: myContact } = useMyContact();

    useAnalyticsPageViewTracker('profile', !!data);

    return (
        <div>
            <MyProfileWithHandlers isLoading={isLoading} myProfileData={data} myContact={myContact} />
        </div>
    );
};
