import { CpDataApi } from 'cp-xhr';
import React from 'react';
import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getMyProfileEndpoint } from '@cp-mx/common';

import { MyProfileResponse } from './examples/ExampleData';

const mockOptionsPrivate: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: MyProfileResponse,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const MyProfileMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: '',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions: mockOptionsPrivate,
    url: getMyProfileEndpoint(),
};

export const MyProfileResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, [MyProfileMock]);

    return <div>{storyFn()}</div>;
};
