import React, { useEffect, useState } from 'react';
import { CpDataApi } from 'cp-xhr';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    NotificationsCenter,
    NotificationCenter,
    RenewalContract,
    formatAsDate,
    getNotificationsByCustomerEndpoint,
    getNotificationsCenterEndpoint,
    updateRenewalCloseEndpoint,
} from '@cp-mx/common';
import { Button } from '@vwfs-bronson/bronson-react';
import { Notification, NotificationStatus, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { renewalPath } from 'components/navigation/paths';
import { useBrandContent } from 'components/contracts';
import { useRenewalContract } from '../useRenewalOption';
import { useNotificationCenter } from '../../notification-center/useNotificationCenter';
import { useRenewalNotificationClose } from '../useNotificationClose';
import { useUpdateNotificationCenter } from 'components/notification-center/useUpdateNotificationCenter';
import { useUnpaidBalance } from 'components/unpaid-balance';
import { useUpdateNotificationCenterComponent } from 'components/notification-center/useUpdateNotificationCenterComponent';

export enum renewalFlowState {
    OptionSelected = 'OPTION_SELECTED',
    NoOptionSelected = 'NO_OPTION_SELECTED',
}
type CloseState = {
    idContract: string;
    flowState: string;
};

export const RenewalNotificationUi: React.FC<{ renewalContract?: RenewalContract[] }> = ({ renewalContract }) => {
    const history = useHistory();

    const { data: notifications } = useNotificationCenter();

    const { data: unpaidDetails } = useUnpaidBalance();

    const hasUnpaidBalance = unpaidDetails && unpaidDetails.unpaidAmount !== 0;

    const { updateNotificationCenter } = useUpdateNotificationCenter();

    const { updateNotificationCenterComponent } = useUpdateNotificationCenterComponent();

    const { t } = useTranslation('renewal-notification');

    const { setRenewalContract } = useRenewalContract();

    let filteredContracts: NotificationsCenter[] = [];

    const { cmsContent: brandCatalog } = useBrandContent();

    const { notificationCloseState, setNotification } = useRenewalNotificationClose();

    const [close, setClose] = useState<CloseState>({ idContract: '', flowState: '' });

    const { onAction: onDigitalRenewalStart } = useAnalyticsActionTracker('onDashboardDigitalRenewal');

    const handleOnClickNotification = (renewalContractSelected: RenewalContract) => {
        setRenewalContract(renewalContractSelected);
        onDigitalRenewalStart();
        history.push(renewalPath());
    };

    const updateNotifications = async (idContract: string, flowState: string) => {
        await CpDataApi.patch(updateRenewalCloseEndpoint(), {
            contractNumber: idContract,
            flowState,
        });
        const newDataNotification = await CpDataApi.get<NotificationsCenter[]>(
            getNotificationsByCustomerEndpoint(),
        ).then(response => response.data);
        updateNotificationCenter(newDataNotification);

        const newDataNotificationCenter = await CpDataApi.get<NotificationCenter[]>(
            getNotificationsCenterEndpoint(),
        ).then(response => response.data);
        updateNotificationCenterComponent(newDataNotificationCenter);
        setClose({
            idContract: '',
            flowState: '',
        });
    };
    const onCloseNotification = async (idContract: string, flowState: string) => {
        setNotification(idContract);
        setClose({
            idContract,
            flowState,
        });
    };
    useEffect(() => {
        if (close.idContract !== '') {
            updateNotifications(close.idContract, close.flowState);
        }
    }, [close]);

    const handleOnClose = (contractNumber: string, flowState: string) => {
        setNotification(contractNumber);
        updateNotifications(contractNumber, flowState);
    };

    const replaceTextDefault = (renewal: RenewalContract) => {
        const brandName = brandCatalog && brandCatalog[renewal.brand] ? brandCatalog[renewal.brand] : renewal.brand;
        const contractEndDate = renewal.contractEndDate ? formatAsDate(renewal.contractEndDate) : '-';
        return t('default.remainder')
            .replace('<endOfContractDate>', contractEndDate)
            .replace('<idContract>', renewal.contractLabel)
            .replace('<brand>', brandName);
    };

    const renderDefaultNotification = (contract: RenewalContract) => {
        return (
            <Notification
                key={`${contract.contractNumber}`}
                showCloseButton
                visible={!notificationCloseState.includes(contract.contractNumber)}
                // headlineIcon={'exclamation-mark-circle'}
                onCloseClick={() => handleOnClose(contract.contractNumber, renewalFlowState.NoOptionSelected)}
                headline={t('default.headline')}
                testId={'testIdDefault'}
            >
                {replaceTextDefault(contract)}
                <Button
                    className="u-font-size-fs0"
                    style={{ textTransform: 'lowercase' }}
                    link
                    onClick={() => handleOnClickNotification(contract)}
                >
                    aquí.
                </Button>
            </Notification>
        );
    };

    const replaceTextSuccessHeadLine = (contractLabel: string) => {
        return t('success.headline').replace('<idContract>', contractLabel);
    };
    const replaceTextSuccessInfo = (optionSelected: string) => {
        return t('success.optionInfo').replace('<optionSelected>', optionSelected);
    };

    const renderSuccessNotification = (optionTitle: string, contractLabel: string, contractNumber: string) => {
        return (
            <Notification
                showCloseButton
                visible={!notificationCloseState.includes(contractNumber)}
                status={NotificationStatus.success}
                onCloseClick={() => onCloseNotification(contractNumber, renewalFlowState.OptionSelected)}
                headline={replaceTextSuccessHeadLine(contractLabel)}
                testId={'testIdSuccess'}
            >
                {replaceTextSuccessInfo(optionTitle)}
            </Notification>
        );
    };

    if (!renewalContract?.length) {
        return null;
    }

    const getContractData = (idContract: string) => {
        return renewalContract.filter(contract => contract.contractNumber === idContract)[0];
    };

    if (notifications && notifications.length) {
        filteredContracts = notifications.filter(item1 =>
            renewalContract.some(item2 => item1.contractNumber === item2.contractNumber && item1['renewal']),
        );
    }
    return (
        <>
            {!hasUnpaidBalance &&
                filteredContracts.map(notification => {
                    const { renewal } = notification;
                    const contract = getContractData(notification.contractNumber);
                    if (renewal && !renewal.dashboardNotificationClosed && renewal.optionSelected) {
                        return renderSuccessNotification(
                            renewal.optionTitle,
                            contract.contractLabel,
                            contract.contractNumber,
                        );
                    } else {
                        if (
                            renewal &&
                            !renewal.notificationCenterBySessionsCount &&
                            !renewal.dashboardNotificationClosed
                        ) {
                            if (renewal && !renewal.optionSelected) {
                                return renderDefaultNotification(contract);
                            } else {
                                return null;
                            }
                        } else {
                            return null;
                        }
                    }
                })}
        </>
    );
};
