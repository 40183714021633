import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Combobox, ErrorMessage, FormField, FormFieldLabel, Layout } from '@vwfs-bronson/bronson-react';
import { RequestInitialValues } from '../initialValues';
import { FormikProps, useField, useFormikContext } from 'formik';
import { Contract } from '@cp-mx/common';
import { useModelContent, useBrandContent, useProductContent } from 'components/contracts';

export type ContractSelectionProps = {
    contracts?: Contract[];
    resetForm: Function;
    currentContractNumber: string;
};

export const ContractSelection: React.FC<ContractSelectionProps> = ({
    contracts,
    resetForm,
    currentContractNumber,
}) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, meta, helpers] = useField('contractSelection');
    const { initialValues }: FormikProps<RequestInitialValues> = useFormikContext();
    const { cmsContent: modelCatalog } = useModelContent();
    const { cmsContent: brandCatalog } = useBrandContent();
    const { cmsContent: productCatalog } = useProductContent();
    const { t } = useTranslation('open-request');

    const productFromCatalog = (contract: Contract): string => {
        if (contract.product.type) {
            return contract.product.type;
        }
        return productCatalog ? productCatalog[contract.product.group] : '-';
    };

    const setValueChange = useCallback(
        (selectedContractNumber: { key: string; value: string }[]) => {
            if (currentContractNumber !== selectedContractNumber[0].value) {
                resetForm({
                    values: {
                        ...initialValues,
                        contractSelection: selectedContractNumber[0].value,
                        categorySelection: 'no-category',
                    },
                });
            }
            return helpers.setValue(selectedContractNumber[0].value || initialValues.contractSelection);
        },
        [currentContractNumber], // eslint-disable-line react-hooks/exhaustive-deps
    );

    const contractOptions = contracts
        ? [...contracts].map(contract => {
              const brand =
                  brandCatalog && brandCatalog[contract.vehicle.brand]
                      ? brandCatalog[contract.vehicle.brand]
                      : contract.vehicle.brand;
              const model =
                  modelCatalog && modelCatalog[contract.vehicle.model]
                      ? modelCatalog[contract.vehicle.model]
                      : contract.vehicle.model;
              const vehicleDetails = brand.concat(' ' + model);
              const productName = contract.product.name ? contract.product.name : productFromCatalog(contract);
              return (
                  <Combobox.Item
                      testId={`contract-selection-item-${contract.contractLabel}`}
                      optionValue={contract.contractLabel}
                      optionKey={contract.contractLabel}
                      key={contract.contractLabel}
                  >
                      <Layout flush>
                          <Layout.Item>
                              <strong>{contract.contractLabel}</strong>
                          </Layout.Item>
                          <Layout.Item>
                              <strong>{productName}</strong>
                          </Layout.Item>
                          <Layout.Item>{vehicleDetails}</Layout.Item>
                      </Layout>
                  </Combobox.Item>
              );
          })
        : [];
    const label = `${t('fields.contracts.label')} *`;
    const generalOption = (
        <Combobox.Item
            testId={`contract-selection-item-general`}
            optionValue={t('fields.contracts.default')}
            optionKey={t('fields.contracts.default')}
            key={`${t('fields.contracts.default')}`}
        >
            <strong>{t('fields.contracts.default')}</strong>
        </Combobox.Item>
    );
    const options = [generalOption, ...contractOptions];
    const preSelected = {
        value: [
            {
                key: t('fields.contracts.default'),
                value: t('fields.contracts.default'),
            },
        ],
    };
    return (
        <FormField
            type="select"
            testId={'contract-selection'}
            id={'contract-selection'}
            errorMessage={
                meta.touched &&
                meta.error && <ErrorMessage testId={'textarea-error-message'}>{meta.error}</ErrorMessage>
            }
        >
            <FormFieldLabel>{label}</FormFieldLabel>
            <Combobox defaultState={preSelected} testId={'custom-contract-select'} onChange={setValueChange}>
                {options}
            </Combobox>
        </FormField>
    );
};
