import React from 'react';
import { DataOverview } from '@vwfs-bronson/bronson-react';
import { ContactData } from '@cp-mx/common';
import { ContactList } from './ContactList';
import { useTranslation } from 'react-i18next';

export const ContactSection: React.FC<{ contact?: ContactData }> = ({ contact }) => {
    const { t } = useTranslation('my-profile-contact-details');
    if (!contact) {
        return null;
    }
    return (
        <DataOverview title={t('headline')}>
            <ContactList contact={contact} />
        </DataOverview>
    );
};
