import { RenewalOption } from '@cp-mx/common';
import { AbstractContentState, useCmsContent } from '@cp-shared-8/frontend-integration';
import {
    fetchRenewalCreditOptions,
    fetchRenewalLeasingOptions,
    fetchRenewalPremiumCreditOptions,
} from './RenewalOptionsSlice';
import { RenewalCreditSelector, RenewalLeasingSelector, RenewalPremiumCreditSelector } from './RenewalOptionsSelector';

export function useRenewalCreditOptions(): AbstractContentState<RenewalOption[]> {
    return useCmsContent(fetchRenewalCreditOptions, RenewalCreditSelector);
}

export function useRenewalLeasingOptions(): AbstractContentState<RenewalOption[]> {
    return useCmsContent(fetchRenewalLeasingOptions, RenewalLeasingSelector);
}

export function useRenewalPremiumCreditOptions(): AbstractContentState<RenewalOption[]> {
    return useCmsContent(fetchRenewalPremiumCreditOptions, RenewalPremiumCreditSelector);
}
