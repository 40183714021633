export type ContactCustomer = {
    phoneNumber?: string;
    cellphone?: string;
    email?: string;
};

export type ContactData = ContactCustomer & {
    customerExist: boolean;
    id?: string;
};

export enum TypeOfPerson {
    PF = 'pf',
    PM = 'pm',
}

export type AddContactCustomer = ContactCustomer & {
    typeOfPerson: TypeOfPerson;
    requestDate: string;
};

export type UpdateContactCustomer = AddContactCustomer & {
    id: string;
};
