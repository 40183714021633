import React from 'react';
import { DataOverview } from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { MyProfileData } from '@cp-mx/common';

export const IdentificationSection: React.FC<{ myProfileData: MyProfileData }> = ({ myProfileData }) => {
    const { t } = useTranslation('my-profile');

    if (!myProfileData) {
        return null;
    }

    const getIdentificationList = (): DefinitionListItem[] => {
        return [
            {
                label: t('identification.customerData.name'),
                value: myProfileData.name || '-',
                testId: 'identification-details-name',
            },
        ];
    };

    const identificationList: DefinitionListItem[] = getIdentificationList();

    return (
        <DataOverview title={t('identification.title')}>
            <DefinitionListHorizontal list={identificationList} />
        </DataOverview>
    );
};
