import { useGetSimpleUpdateData } from '@cp-shared-8/frontend-integration';
import { MktPreferencesResponse } from '@cp-mx/common';
import { updateMyMktPreferences } from '../MyMktPreferencesSlice';
import { MyMktPreferencesSelector } from '../MyMktPreferencesSelector';

type UpdateMktPreferencesType = {
    updateMyMktPreferences: (data: MktPreferencesResponse) => void;
    mktPreferences: MktPreferencesResponse;
};

export function useUpdateMktPreferences(): UpdateMktPreferencesType {
    const {
        updateData,
        dataState: { data },
    } = useGetSimpleUpdateData(updateMyMktPreferences, MyMktPreferencesSelector);
    return {
        updateMyMktPreferences: updateData,
        mktPreferences: data as MktPreferencesResponse,
    };
}
