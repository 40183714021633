import { getBrandCatalogEndpoint, Brand } from '@cp-mx/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<Brand>({
    contentName: 'brand-catalog',
    contentEndpoint: getBrandCatalogEndpoint,
});

export default reducer;
export const fetchBrandCatalog = fetchContent;
