import React from 'react';

import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';

import { PrivacyPolicyUi } from './ui';
import { usePrivacyPolicy } from './usePrivacyPolicy';

const PrivacyPolicyWithHandlers = withLoadingAndNoConnectionHandler(PrivacyPolicyUi);

export const PrivacyPolicy: React.FC = () => {
    const { cmsContent: privacyPolicy, isLoading, loadingError } = usePrivacyPolicy();

    return <PrivacyPolicyWithHandlers isLoading={isLoading} privacyPolicy={privacyPolicy} hasError={!!loadingError} />;
};
