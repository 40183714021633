import React from 'react';
import { FormSectionGroup } from '@vwfs-bronson/bronson-react';
import { Contract } from '@cp-mx/common';
import { ContractOverview } from '../contract-overview/ContractOverview';

export const ContractUi: React.FC<{ contracts?: Contract[] }> = ({ contracts }) => {
    if (!contracts) {
        return null;
    }

    return (
        <FormSectionGroup>
            {contracts.map((contract, index) => (
                <ContractOverview contract={contract} key={contract.contractNumber} defaultExpanded={index === 0} />
            ))}
        </FormSectionGroup>
    );
};
