import { Greeting, getGreetingEndpoint } from '@cp-mx/common';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';
import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { CpDataApi } from 'cp-xhr';

const { reducer, fetchData } = getSimpleDataFetchSlice<Greeting, DefaultBusinessMarketApiErrorCode>({
    dataName: 'greeting',
    fetchCallback() {
        return CpDataApi.get(getGreetingEndpoint()).then(response => response.data);
    },
});

export default reducer;
export const fetchGreeting = fetchData;
