export enum ContactType {
    SMS = 'sms',
    Email = 'email',
}

export type OtpSMS = {
    contactType: ContactType.SMS;
    phoneNumber: string;
};

export type OtpEmail = {
    contactType: ContactType.Email;
    email: string;
};

export type GenerateOtpSMS = OtpSMS & {
    countryCode: string;
    expirationMinutes?: number;
    otpLength?: number;
    customize?: {
        message?: string;
    };
};

export type GenerateOtpEmail = OtpEmail & {
    expirationMinutes?: number;
    otpLength?: number;
    customize?: {
        subject?: string;
        base64Template?: string;
        message?: string;
        templateName?: string;
    };
};

export type ValidateOtpSMS = OtpSMS & {
    otpCode: string;
};

export type ValidateOtpEmail = OtpEmail & {
    otpCode: string;
};
