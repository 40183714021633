import {
    RenewalOption,
    getRenewalCreditCatalogEndpoint,
    getRenewalLeasingCatalogEndpoint,
    getRenewalPremiumCreditCatalogEndpoint,
} from '@cp-mx/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer: credit, fetchContent: fetchCredit } = createCmsContentSlice<RenewalOption[]>({
    contentName: 'renewalCreditOptions',
    contentEndpoint: getRenewalCreditCatalogEndpoint,
});
const { reducer: leasing, fetchContent: fetchLeasing } = createCmsContentSlice<RenewalOption[]>({
    contentName: 'renewalLeasingOptions',
    contentEndpoint: getRenewalLeasingCatalogEndpoint,
});
const { reducer: premiumCredit, fetchContent: fetchPremiumCredit } = createCmsContentSlice<RenewalOption[]>({
    contentName: 'renewalPremiumCreditOptions',
    contentEndpoint: getRenewalPremiumCreditCatalogEndpoint,
});

export const creditReducer = credit;
export const fetchRenewalCreditOptions = fetchCredit;

export const leasingReducer = leasing;
export const fetchRenewalLeasingOptions = fetchLeasing;

export const premiumCreditReducer = premiumCredit;
export const fetchRenewalPremiumCreditOptions = fetchPremiumCredit;
