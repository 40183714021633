import React from 'react';
import { CookieWarning as CookieWarningUi } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { cookiePolicyPath } from 'components/navigation/paths';

export const CookieWarning: React.FC = () => {
    const { t } = useTranslation('cookie-warning');

    return (
        <CookieWarningUi
            warningText={t('warningText')}
            acceptLabel={t('acceptLabel')}
            cookiePolicyLink={cookiePolicyPath()}
            linkText={t('linkText')}
        />
    );
};
