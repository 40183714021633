import { UnauthorizedContent, getUnauthorizedEndpoint } from '@cp-mx/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<UnauthorizedContent>({
    contentName: 'Unauthorized',
    contentEndpoint: getUnauthorizedEndpoint,
});

export default reducer;
export const fetchUnauthorized = fetchContent;
