import { Contract } from '@cp-mx/common';
import moment from 'moment';

const leasingGroup = '50';
const premiumCreditgroup = '70';

export const paymentDate = (contract: Contract): string => {
    const now = new Date();
    const date = new Date(now.getTime() - 300 * 60000);
    date.setDate(1);
    const startOfBilling = moment(contract.startOfBilling)
        .utc()
        .toDate();

    if (startOfBilling.getFullYear() === now.getFullYear() && startOfBilling.getMonth() >= now.getMonth()) {
        date.setMonth(startOfBilling.getMonth() + 1);
    } else {
        if (now.getDate() > Number(contract.paymentDay)) {
            date.setMonth(date.getMonth() + 1);
        }
    }
    date.setDate(Number(contract.paymentDay));
    return date.toISOString();
};

export const validateLeasingContract = (group: string): boolean => {
    return group === leasingGroup;
};
export const validatePremiumCreditContract = (group: string): boolean => {
    return group === premiumCreditgroup;
};
