import { UnauthorizedUi } from './ui/UnauthorizedUi';
import { withLoadingAndNoConnectionHandler } from '../integration-wrapper';
import React from 'react';
import { useUnauthorized } from './useUnauthorized';

export const Unauthorized: React.FC = () => {
    const { cmsContent: UnauthorizedContent, isLoading, loadingError } = useUnauthorized();
    const UnauthorizedWithHandlers = withLoadingAndNoConnectionHandler(UnauthorizedUi);

    return (
        <UnauthorizedWithHandlers
            isLoading={isLoading}
            hasError={!!loadingError}
            headline={UnauthorizedContent?.headline || ''}
            text={UnauthorizedContent?.text || ''}
        />
    );
};
