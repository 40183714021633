import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { NotificationsCenterUI } from './ui/NotificationsCenterUi';
import { useNotificationCenterComponent } from './useNotificationsCenterComponent';

const NotificationCenterWithHandlers = withLoadingAndNoConnectionHandler(NotificationsCenterUI);

export const NotificationCenter: React.FC = () => {
    const { data, isLoading } = useNotificationCenterComponent();
    return <NotificationCenterWithHandlers isLoading={isLoading} notifications={data} hasError={false} />;
};
