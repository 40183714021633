import { RenewalContract } from '@cp-mx/common';
import { RenewalOptionSelector } from './RenewalOptionSelector';
import { useDispatch, useSelector } from 'react-redux';

export const useRenewalContract = () => {
    const dispatch = useDispatch();
    const renewalContractState = useSelector(RenewalOptionSelector) as RenewalContract;

    return {
        renewalContractState,
        setRenewalContract: (contract: RenewalContract) => {
            dispatch({ type: 'renewalContractSelected', data: contract });
        },
    };
};
