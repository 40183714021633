export const getContractSelectionInitialValues = (): string => {
    return 'Solicitud General';
};

export const getCategorySelectionInitialValue = (): string => {
    return 'no-category';
};

export type RequestInitialValues = {
    contractSelection: string;
    categorySelection: string;
    inputField1: string;
    inputField2: string;
};

export const getInitialValues: () => RequestInitialValues = () => ({
    contractSelection: getContractSelectionInitialValues(),
    categorySelection: getCategorySelectionInitialValue(),
    inputField1: '',
    inputField2: '',
});
