import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { DATE_FORMAT } from '@cp-mx/common';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { getAge } from './utils';

export const personIdentificationFormValidationSchema = (t: TFunction) => {
    const translationPrefix = 'registration-form.person.validation-errors';
    return Yup.object().shape({
        privateCustomerNumber: Yup.string()
            .trim()
            .required(t(`${translationPrefix}.customer-number.missing`))
            .test(
                'format',
                t(`${translationPrefix}.customer-number.format`),
                customerNumber => !!(customerNumber && customerNumber.match(/^\d{6,7}$/g)),
            ),
        dateOfBirth: Yup.string()
            .required(t(`${translationPrefix}.birth-date.missing`))
            .test('format', t(`${translationPrefix}.birth-date.format`), date =>
                formatCpDate(date, DATE_FORMAT).isValid(),
            )
            .test(
                'min age',
                t(`${translationPrefix}.birth-date.min-age`),
                date => !!(date && getAge(new Date(formatCpDate(date, DATE_FORMAT).toCpDate())) >= 18),
            )
            .test(
                'max age',
                t(`${translationPrefix}.birth-date.max-age`),
                date => !!(date && getAge(new Date(formatCpDate(date, DATE_FORMAT).toCpDate())) <= 100),
            ),
        confirmTermsAndConditions: Yup.bool().oneOf([true], t(`${translationPrefix}.terms-and-conditions`)),
        confirmPrivacyPolicy: Yup.bool().oneOf([true], t(`${translationPrefix}.privacy-policy`)),
    });
};
