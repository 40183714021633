import * as Yup from 'yup';
import { TFunction } from 'i18next';
import { formatCpDate } from '@cp-shared-8/common-utilities';
import { DATE_FORMAT } from '@cp-mx/common';

export const businessIdentificationFormValidationSchema = (t: TFunction) => {
    const translationPrefis = 'registration-form.business.validation-errors';
    return Yup.object().shape({
        businessCustomerNumber: Yup.string()
            .trim()
            .required(t(`${translationPrefis}.customer-number.missing`))
            .test(
                'format',
                t(`${translationPrefis}.customer-number.format`),
                businessCustomerNumber => !!(businessCustomerNumber && businessCustomerNumber.match(/^\d{6,7}$/g)),
            ),
        dateFounded: Yup.string()
            .required(t(`${translationPrefis}.date-founded.missing`))
            .test('format', t(`${translationPrefis}.date-founded.format`), date =>
                formatCpDate(date, DATE_FORMAT).isValid(),
            ),
        confirmTermsAndConditions: Yup.bool().oneOf([true], t(`${translationPrefis}.terms-and-conditions`)),
        confirmPrivacyPolicy: Yup.bool().oneOf([true], t(`${translationPrefis}.privacy-policy`)),
    });
};
