import React from 'react';
import { MediaTeaser as MediaTeaserShared, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { LandingPageMarketingCard } from '@cp-mx/common';
import { ContentSection, Layout, PageWrap } from '@vwfs-bronson/bronson-react';

export const LandingPageMarketingCardsUi: React.FC<{ landingPageMarketingCards?: LandingPageMarketingCard[] }> = ({
    landingPageMarketingCards: cards,
}) => {
    const { onAction } = useAnalyticsActionTracker('onClickMarketingCard');

    if (!cards) {
        return null;
    }

    return (
        <ContentSection>
            <PageWrap size={'medium'}>
                <Layout>
                    {cards.map(({ title, imageUrl, pretitle, text, buttonLabel, buttonUrl }, index) => (
                        <Layout.Item key={index}>
                            <MediaTeaserShared
                                title={title || ''}
                                imageUrl={imageUrl || ''}
                                pretitle={pretitle}
                                text={text}
                                buttonLabel={buttonLabel}
                                clickHandler={() => {
                                    onAction(title);
                                    window.open(buttonUrl, '_blank');
                                }}
                                reversed={index % 2 === 1}
                            />
                        </Layout.Item>
                    ))}
                </Layout>
            </PageWrap>
        </ContentSection>
    );
};
