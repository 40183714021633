import { LegalFooter, getLegalFooterEndpoint } from '@cp-mx/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<LegalFooter>({
    contentName: 'legalFooter',
    contentEndpoint: getLegalFooterEndpoint,
});

export default reducer;
export const fetchLegalFooter = fetchContent;
