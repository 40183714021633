import React from 'react';
import { DataOverview } from '@vwfs-bronson/bronson-react';
import { ContactData } from '@cp-mx/common';
import { MktPreferencesList } from './MktPreferencesList';
import { useMyMktPreferences } from '../useMyMktPreferences';

export const MktPreferencesSection: React.FC<{ contact?: ContactData }> = ({ contact }) => {
    const { data } = useMyMktPreferences();
    if (!contact || (contact && !contact.phoneNumber && !contact.cellphone && !contact.email)) {
        return null;
    } else {
        return (
            <DataOverview>
                <MktPreferencesList mktPreferences={data} />
            </DataOverview>
        );
    }
};
