import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';

import { UnpaidBalanceUi } from './ui';
import { useUnpaidBalance } from './useUnpaidBalance';

const UnpaidBalanceWithHandlers = withLoadingAndNoConnectionHandler(UnpaidBalanceUi);

export const UnpaidBalance: React.FC = () => {
    const { data: unpaidDetails, isLoading, loadingError } = useUnpaidBalance();

    return <UnpaidBalanceWithHandlers isLoading={isLoading} unpaidDetails={unpaidDetails} hasError={!!loadingError} />;
};
