import React from 'react';
import { Contract, VehicleDetails } from '@cp-mx/common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { ErrorNotification } from 'components/notifications/error/ErrorNotification';
import { useSelector } from 'react-redux';
import { modelCatalogContentSelector, brandCatalogContentSelector } from 'components/contracts';
import { Tag } from '@vwfs-bronson/bronson-react';

export const VehicleDetailsUi: React.FC<{ contract?: Contract; vehicleDetails?: VehicleDetails }> = ({
    vehicleDetails,
    contract,
}) => {
    const { t } = useTranslation('vehicle-details');
    const { cmsContent: modelCatalog } = useSelector(modelCatalogContentSelector);
    const { cmsContent: brandCatalog } = useSelector(brandCatalogContentSelector);

    if (!contract || !vehicleDetails) {
        return (
            <ErrorNotification
                testId={'no-connection-error'}
                headline={t('error.noConnection.title')}
                text={t('error.noConnection.text')}
            />
        );
    }

    const brand =
        brandCatalog && brandCatalog[vehicleDetails.brand] ? brandCatalog[vehicleDetails.brand] : vehicleDetails.brand;
    const model =
        modelCatalog && modelCatalog[vehicleDetails.model] ? modelCatalog[vehicleDetails.model] : vehicleDetails.model;
    const vehicle = brand.concat(' ' + model);

    const getListItem = (vehicleDetails: string, vinCode: string): DefinitionListItem[] => {
        const items: DefinitionListItem[] = [];
        if (vehicleDetails && vehicleDetails.length > 0) {
            items.push({
                label: t('model'),
                value: vehicleDetails,
                testId: 'model',
            });
        } else {
            items.push({
                label: t('model'),
                value: <Tag error={true}> {t('errorValue')}</Tag>,
                testId: 'model',
            });
        }
        if (vinCode && vinCode.length > 0) {
            items.push({
                label: t('vin'),
                value: vinCode,
                testId: 'vin',
            });
        } else {
            items.push({
                label: t('vin'),
                value: <Tag error={true}> {t('errorValue')}</Tag>,
                testId: 'vin',
            });
        }
        return items;
    };

    return <DefinitionListHorizontal list={getListItem(vehicle, vehicleDetails.vinCode)} />;
};
