import { NotificationCenter, getNotificationsCenterEndpoint } from '@cp-mx/common';
import { getSimpleDataFetchSlice } from '@cp-shared-8/frontend-integration';
import { CpDataApi } from 'cp-xhr';
import { DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';

const { reducer, fetchData, updateData } = getSimpleDataFetchSlice<
    NotificationCenter[],
    DefaultBusinessMarketApiErrorCode
>({
    dataName: 'notificationsCenter',
    fetchCallback() {
        return CpDataApi.get<NotificationCenter[]>(getNotificationsCenterEndpoint()).then(response => response.data);
    },
});

export default reducer;
export const fetchNotificationsCenterAll = fetchData;
export const updateNotificationCenter = updateData;
