/**
 * Contracts
 * @returns Contracts that applies for a renewal
 */
export function getRenewalContractsEndpoint(): string {
    return '/renewal/contracts';
}

/**
 * To save customer renewal choice
 * @returns message
 */
export function getRenewalChoiceEndpoint(): string {
    return '/renewal/choice';
}

/**
 * To send email for customer renewal choice
 * @returns message
 */
export function postSendEmailRenewalChoiceEndpoint(): string {
    return '/renewal/send-email';
}
