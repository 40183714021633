import React from 'react';
import { MyProfileData, ContactData } from '@cp-mx/common';
import { IdentificationSection } from '../identification-section';
import { AddressSection } from '../addresses-section';
import { ErrorNotification } from '../../notifications/error/ErrorNotification';
import { useTranslation } from 'react-i18next';
import { ContactSection } from '../contact-section';
import { MktPreferencesSection } from '../mkt-preferences-section';

export const MyProfileUi: React.FC<{
    myProfileData?: MyProfileData;
    myContact?: ContactData;
}> = ({ myProfileData, myContact }) => {
    const { t } = useTranslation('my-profile');

    if (!myProfileData || !myProfileData.name) {
        return (
            <ErrorNotification
                testId="my-profile-error"
                headline={t('error.no-connection.headline')}
                text={t('error.no-connection.text')}
            />
        );
    }

    return (
        myProfileData && (
            <>
                <IdentificationSection myProfileData={myProfileData} />
                <AddressSection address={myProfileData.address} />
                <ContactSection contact={myContact} />
                <MktPreferencesSection contact={myContact} />
            </>
        )
    );
};
