import { RenewalContract } from '@cp-mx/common';
import { Reducer } from 'redux';

export const renewalOptionContractReducer: Reducer<RenewalContract> = (
    state = {
        contractEndDate: '',
        contractNumber: '',
        contractLabel: '',
        vehicle: '',
        brand: '',
        financeProduct: '',
        term: '',
        vin: '',
    },
    action,
) => (action.type === 'renewalContractSelected' ? action.data : state);
