import React, { useState } from 'react';
import moment from 'moment';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useAnalyticsActionTracker, useAnalyticsPageViewTracker, ValidatedTextarea } from '@cp-shared-8/frontend-ui';
import { CpDataApi } from '../../../cp-xhr';
import { getCategorySelectionInitialValue, getInitialValues, RequestInitialValues } from './initialValues';
import { validationSchema } from './validationSchema';
import { ContractSelection } from './contract-selection';
import {
    Button,
    ButtonContainer,
    ContentSection,
    Fieldset,
    Heading,
    Layout,
    Modal,
    Spinner,
} from '@vwfs-bronson/bronson-react';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from '../../navigation/paths';

import {
    Contract,
    CustomerRequest,
    getCreateCustomerRequestEndpoint,
    getSendMailEndpoint,
    MailRequest,
} from '@cp-mx/common';
import { CategorySelection } from './category-selection';
import { useBrandContent, useModelContent } from 'components/contracts';

enum FinancialProduct {
    credit = 'Credit',
    leasing = 'Leasing',
    premium = 'Premium Credit',
}
enum MessageType {
    ERROR = 'ERROR',
    SUCCESS = 'SUCCESS',
    VALIDATION_ERROR = 'VALIDATION_ERROR',
    NONE = 'NONE',
}

export const FormView: React.FC<{
    contracts?: Contract[];
}> = ({ contracts }) => {
    useAnalyticsPageViewTracker('requests');

    const { t } = useTranslation('open-request');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const history = useHistory();
    const [messageType, setMessageType] = useState<MessageType>(MessageType.NONE);
    const { cmsContent: modelCatalog } = useModelContent();
    const { cmsContent: brandCatalog } = useBrandContent();
    const { onAction: onSuccess } = useAnalyticsActionTracker('onRequestsSuccess');
    const { onAction: onError } = useAnalyticsActionTracker('onRequestsError');

    if (!contracts) {
        return null;
    }

    const mapperCustomerRequest = (values: RequestInitialValues): CustomerRequest => {
        if (values.contractSelection === 'Solicitud General') {
            return {
                requestDate: moment().format('DD/MM/YYYY HH:mm:ss'),
                requestType: 'generalRequest',
                requestCategory: values.categorySelection,
                shortDescription: values.inputField1,
                requestText: values.inputField2,
            };
        } else {
            const contractSelected = contracts.find(contract => contract.contractLabel === values.contractSelection);
            const brandSelected = contractSelected?.vehicle.brand as string;
            const modelSelected = contractSelected?.vehicle.model as string;
            const brand = brandCatalog && brandCatalog[brandSelected] ? brandCatalog[brandSelected] : brandSelected;
            const model = modelCatalog && modelCatalog[modelSelected] ? modelCatalog[modelSelected] : modelSelected;
            return {
                requestDate: moment().format('DD/MM/YYYY HH:mm:ss'),
                requestType: 'contractRequest',
                requestCategory: values.categorySelection,
                shortDescription: values.inputField1,
                requestText: values.inputField2,
                contractNumber: values.contractSelection,
                financialProduct: contractSelected?.product.type as FinancialProduct,
                carModel: `${brand} ${model}`,
            };
        }
    };
    const mapperMailrequest = (values: RequestInitialValues): MailRequest => {
        if (values.contractSelection === t('fields.contracts.default')) {
            return {
                categoryName: values.categorySelection,
                shortDescription: values.inputField1,
                request: values.inputField2,
            };
        } else {
            return {
                contractNumber: values.contractSelection,
                categoryName: values.categorySelection,
                shortDescription: values.inputField1,
                request: values.inputField2,
            };
        }
    };
    const handleSubmit = (values: RequestInitialValues, { resetForm }: { resetForm: Function }): void => {
        (async (): Promise<void> => {
            const category = values.categorySelection;
            if (category === 'no-category') return;

            const mappingResult = await mapperMailrequest(values);
            const customerRequestMapping = mapperCustomerRequest(values);
            CpDataApi.post(getSendMailEndpoint(), mappingResult)
                .then(() => CpDataApi.post(getCreateCustomerRequestEndpoint(), customerRequestMapping))
                .then(() => {
                    onSuccess(category);
                    setIsSubmitting(false);
                    resetForm();
                    setMessageType(MessageType.SUCCESS);
                })
                .catch(() => {
                    onError(category);
                    setIsSubmitting(false);
                    setMessageType(MessageType.ERROR);
                });
        })();
        setIsSubmitting(true);
    };

    const handleModalClose = (): void => setMessageType(MessageType.NONE);

    const handleSuccessModalClose = (resetForm: Function): void => {
        resetForm({ values: getInitialValues() });
        handleModalClose();
    };

    const handleSuccessModalConfirm = (): void => {
        handleModalClose();
        history.push(dashboardPagePath());
    };

    return (
        <Formik
            initialValues={getInitialValues()}
            validationSchema={validationSchema(t)}
            onSubmit={handleSubmit}
            validateOnBlur={true}
        >
            {({ values, submitForm, resetForm, dirty, isValid }): JSX.Element => (
                <Form onSubmit={(e): void => e.preventDefault()} data-testid="request-form">
                    <ContentSection pageWrapSize="medium" className={'request-certificate-form'}>
                        <Heading level={1} className="u-mb-large">
                            {t('headline')}
                        </Heading>
                        <Fieldset>
                            <Fieldset.Row>
                                <Layout>
                                    <Layout.Item>
                                        <ContractSelection
                                            contracts={contracts}
                                            resetForm={resetForm}
                                            currentContractNumber={values.contractSelection}
                                        />
                                    </Layout.Item>
                                    <Layout.Item>
                                        <CategorySelection
                                            resetForm={resetForm}
                                            contractSelection={values.contractSelection}
                                            currentCategorySelection={values.categorySelection}
                                        />
                                    </Layout.Item>
                                </Layout>
                            </Fieldset.Row>
                            {values.categorySelection !== getCategorySelectionInitialValue() ? (
                                <>
                                    <Fieldset.Row>
                                        <ValidatedTextarea
                                            label={t('fields.inputField1.label')}
                                            placeholder={t('fields.inputField1.placeholder')}
                                            name={'inputField1'}
                                            maxLength={40}
                                            isMandatory
                                            testId={'inputField1-test'}
                                        />
                                    </Fieldset.Row>
                                    <Fieldset.Row>
                                        <ValidatedTextarea
                                            label={t('fields.inputField2.label')}
                                            placeholder={t('fields.inputField2.placeholder')}
                                            name={'inputField2'}
                                            maxLength={1000}
                                            isMandatory
                                            testId={'inputField2-test'}
                                        />
                                    </Fieldset.Row>
                                </>
                            ) : null}
                            <Fieldset.Row>
                                <ButtonContainer center>
                                    <Button
                                        secondary
                                        testId={'dashboard-button'}
                                        onClick={(): void => {
                                            history.push(dashboardPagePath());
                                        }}
                                    >
                                        {t('buttons.cancel')}
                                    </Button>
                                    {isSubmitting ? (
                                        <Spinner small />
                                    ) : (
                                        <Button
                                            testId={'submit-button'}
                                            type="submit"
                                            onClick={async (): Promise<void> => {
                                                (!dirty ||
                                                    !isValid ||
                                                    values.categorySelection === getCategorySelectionInitialValue()) &&
                                                    setMessageType(MessageType.VALIDATION_ERROR);
                                                await submitForm();
                                            }}
                                        >
                                            {t('buttons.submit')}
                                        </Button>
                                    )}
                                </ButtonContainer>
                            </Fieldset.Row>
                        </Fieldset>
                    </ContentSection>
                    <Modal
                        shown={messageType === MessageType.ERROR}
                        status="error"
                        title={t('notifications.error.headline')}
                        onClose={handleModalClose}
                        onCancel={handleModalClose}
                        onClickOutside={handleModalClose}
                        buttonCancelText={t('notifications.error.button')}
                        testId={'error-modal'}
                    >
                        {t('notifications.error.message')}
                    </Modal>
                    <Modal
                        shown={messageType === MessageType.SUCCESS}
                        status="success"
                        title={t('notifications.success.headline')}
                        onClose={(): void => handleSuccessModalClose(resetForm)}
                        onConfirm={handleSuccessModalConfirm}
                        onClickOutside={(): void => handleSuccessModalClose(resetForm)}
                        buttonConfirmText={t('notifications.success.button')}
                        testId={'success-modal'}
                    >
                        {t('notifications.success.message')}
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
