import { CpDataApi } from 'cp-xhr';
import React from 'react';

import { EndpointMockDescription, MockOptions, setupMockResponses } from '@cp-shared-8/frontend-storybook-extensions';
import { DefaultBusinessMarketApiError, DefaultBusinessMarketApiErrorCode } from '@cp-shared-8/common-utilities';

import { getDashboardMarketingCardEndpoint } from '@cp-mx/common';

import { DashboardMarketingCardWithAllValues } from './ExampleData';

const mockOptions: MockOptions<DefaultBusinessMarketApiErrorCode> = {
    Success: {
        status: 200,
        responseBody: DashboardMarketingCardWithAllValues,
    },
    'Business Error': DefaultBusinessMarketApiError,
    'Unexpected/Connection Error': {
        status: 500,
    },
};

export const DashboardMarketingCardMock: EndpointMockDescription<DefaultBusinessMarketApiErrorCode> = {
    name: 'dashboard-marketing-card',
    method: 'get',
    defaultMockOption: 'Success',
    mockOptions,
    url: getDashboardMarketingCardEndpoint('es', 'vwfs'),
};

export const DashboardResponseMockWrapper = (storyFn: () => React.ReactNode) => {
    setupMockResponses(CpDataApi, DashboardMarketingCardMock);

    return <div>{storyFn()}</div>;
};
