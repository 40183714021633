export enum FetchContentError {
    DEFAULT_ERROR = 'DEFAULT_ERROR',
}

export type ContentEndpoint = (locale: string, brand: string) => string;

function getContentEndpoint(locale: string, brand: string, contentType: string): string {
    return `/${brand}/${locale}/${contentType}.json`;
}

export const getIconFooterEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'icon-footer');
};

export const getLegalFooterEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'legal-footer');
};

export const getCookieWarningEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'cookie-warning');
};

export const getFaqEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'faq');
};

export const getFaqTeaserEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'faq-teaser');
};

export const getLandingPageTeaserEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'landing-page-teaser');
};

export const getLandingPageMarketingCardEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'landing-page-marketing-cards');
};

export const getDashboardMarketingCardEndpoint: ContentEndpoint = (locale, brand) => {
    return getContentEndpoint(locale, brand, 'dashboard-marketing-card');
};

export const getPrivacyPolicyEndpoint: ContentEndpoint = (locale: string, brand) => {
    return getContentEndpoint(locale, brand, 'privacy-policy');
};

export const getCookiePolicyEndpoint: ContentEndpoint = (locale: string, brand) => {
    return getContentEndpoint(locale, brand, 'cookie-policy');
};
export const getLegalNoticeEndpoint: ContentEndpoint = (locale: string, brand) => {
    return getContentEndpoint(locale, brand, 'legal-notice');
};

export const getBrandCatalogEndpoint: ContentEndpoint = (locale: string, brand) => {
    return getContentEndpoint(locale, brand, 'brand-catalog');
};
export const getModelCatalogEndpoint: ContentEndpoint = (locale: string, brand) => {
    return getContentEndpoint(locale, brand, 'model-catalog');
};
export const getProductCatalogEndpoint: ContentEndpoint = (locale: string, brand) => {
    return getContentEndpoint(locale, brand, 'product-catalog');
};
export const getContractDetailsEndpoint: ContentEndpoint = (locale: string, brand) => {
    return getContentEndpoint(locale, brand, 'contract-details');
};
export const getProductNameCatalogEndpoint: ContentEndpoint = (locale: string, brand) => {
    return getContentEndpoint(locale, brand, 'product-name-catalog');
};

export const getUnauthorizedEndpoint: ContentEndpoint = (locale: string, brand) => {
    return getContentEndpoint(locale, brand, 'unauthorized');
};
export const getCategoryCatalogEndpoint: ContentEndpoint = (locale: string, brand) => {
    return getContentEndpoint(locale, brand, 'category-catalog');
};

export const getRenewalCreditCatalogEndpoint: ContentEndpoint = (locale: string, brand) => {
    return getContentEndpoint(locale, brand, 'renewal-credit-catalog');
};

export const getRenewalLeasingCatalogEndpoint: ContentEndpoint = (locale: string, brand) => {
    return getContentEndpoint(locale, brand, 'renewal-leasing-catalog');
};

export const getRenewalPremiumCreditCatalogEndpoint: ContentEndpoint = (locale: string, brand) => {
    return getContentEndpoint(locale, brand, 'renewal-premium-credit-catalog');
};
