import {
    BusinessRegistrationData,
    DATE_FORMAT,
    getBusinessRegistrationEndpoint,
    RegistrationError,
    RegistrationResult,
} from '@cp-mx/common';
import { WithDefaultCpIntegrationErrors, formatCpDate } from '@cp-shared-8/common-utilities';
import { CpDataApi } from 'cp-xhr';
import { BusinessFormProps } from '../types';

export const handleSubmit = (
    formValues: BusinessFormProps,
    successCallback: (registrationResult: RegistrationResult, registrationData: BusinessFormProps) => void,
    errorCallback: (errorCode: WithDefaultCpIntegrationErrors<RegistrationError>, errorMessage: string) => void,
): void => {
    const { businessCustomerNumber, dateFounded } = formValues;
    const registrationData: BusinessRegistrationData = {
        idCustomer: businessCustomerNumber,
        dateFounded: formatCpDate(dateFounded, DATE_FORMAT).toCpDate(),
    };
    CpDataApi.post(getBusinessRegistrationEndpoint(), registrationData)
        .then(response => {
            const responseData: RegistrationResult = response.data;
            successCallback(responseData, formValues);
        })
        .catch(error => {
            const errorCode = error.response?.data?.code;
            const errorMessage = error.response?.data?.message;
            errorCallback(errorCode, errorMessage);
        });
};
