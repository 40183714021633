import React, { useRef } from 'react';
import { useFaq } from './useFaq';
import { Faq as FaqComponent, useAnalyticsActionTracker, useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';
import { ContentSection } from '@vwfs-bronson/bronson-react';

/**
 * Component responsible for providing the FAQ content section
 */
export const Faq: React.FC = () => {
    const { cmsContent: faqData } = useFaq();
    const { onAction: onFaqQuestionAction } = useAnalyticsActionTracker('onFaqQuestion');
    const previousItems = useRef<string[]>([]);
    const onChange = (items: string[]): void => {
        if (items.length > previousItems.current.length) {
            const newItems = items.filter((item: string): boolean => !previousItems.current.includes(item));
            newItems.forEach((item: string): void => onFaqQuestionAction(item));
        }
        previousItems.current = items;
    };

    useAnalyticsPageViewTracker('faq', !!faqData);
    return (
        <ContentSection pageWrapSize="medium">
            <FaqComponent
                header={faqData?.header || ''}
                descriptionText={faqData?.descriptionText}
                items={faqData?.items}
                onChange={onChange}
            />
        </ContentSection>
    );
};
