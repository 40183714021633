/**
 * Array of brands that can see the warranty card on the dashboard
 */
export const WARRANTY_BRANDS: Array<string> = ['VOLK', 'SEAT', 'CUPRA'];

/**
 * Array of models that can`t see the warranty card on the dashboard
 */
export const NO_WARRANTY_MODELS: Array<string> = ['CADD', 'AMAR', 'TRAN', 'CRAF'];

/**
 * this function returns the stage
 */
const getStage = (): string => {
    const stage = process.env.REACT_APP_INTEGRATION_API_BASE_URL;
    if (stage) {
        if (stage.includes('dev.')) return 'dev';
        if (stage.includes('int.')) return 'int';
        if (stage.includes('cons.')) return 'cons';
        return 'prod';
    } else {
        return 'dev';
    }
};

/**
 * Stage
 */
export const STAGE = getStage();
