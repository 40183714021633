export function getMyProfileEndpoint(): string {
    return `/my-profile`;
}

export function getContactDataEndpoint(): string {
    return `/contact-data`;
}

export function getMktPreferencesEndpoint(): string {
    return `/marketing-preferences`;
}
