import React from 'react';
import { IconFooter as IconFooterShared } from '@cp-shared-8/frontend-ui';
import { IconFooter } from '@cp-mx/common';

export const IconFooterUi: React.FC<{ iconFooter?: IconFooter }> = ({ iconFooter }) => {
    if (!iconFooter) {
        return null;
    }

    return <IconFooterShared title={iconFooter.title} items={iconFooter.items} />;
};
