import { CategoryCatalogDropDown, getCategoryCatalogEndpoint } from '@cp-mx/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<CategoryCatalogDropDown>({
    contentName: 'category-catalog',
    contentEndpoint: getCategoryCatalogEndpoint,
});

export default reducer;
export const fetchCategorySelection = fetchContent;
