import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { CpDataApi } from 'cp-xhr';
import { ContactableOptions, getMktPreferencesEndpoint, MktPreferencesResponse } from '@cp-mx/common';
import {
    Notification,
    NotificationStatus,
    Spinner,
    ValidatedCheckboxGroup,
    useAnalyticsActionTracker,
    useAnalyticsFormTracker,
    useAnalyticsPageViewTracker,
} from '@cp-shared-8/frontend-ui';
import { Layout, ButtonContainer, Button, RadioButtonGroup, RadioButton } from '@vwfs-bronson/bronson-react';
import { Formik, Field } from 'formik';
import { useMyContact } from '../useMyContact';
import { useUpdateMktPreferences } from './useMyContactUpdate';

export const MktPreferencesList: React.FC<{ mktPreferences?: MktPreferencesResponse }> = ({ mktPreferences }) => {
    const { t } = useTranslation('my-profile-mkt-preferences');
    const { updateMyMktPreferences } = useUpdateMktPreferences();
    const { data: contact } = useMyContact();

    const [sendingRequest, setSendingRequest] = useState<boolean>(false);
    const [allowEdit, setAllowEdit] = useState<boolean>(false);

    const trackingSectionType = '(VWFS)';
    useAnalyticsPageViewTracker('editProfileSectionDisplayed', true, `Consent settings ${trackingSectionType}`);
    const { onAction: onChanged } = useAnalyticsActionTracker('onMarketingAgreementChanged');
    const { onError, onSuccess } = useAnalyticsFormTracker({
        confirmError: 'onEditProfileConsentSettingsSubmitFailed',
        confirmSuccess: 'onEditProfileConsentSettingsSuccess',
    });

    const [responseNotification, setResponseNotification] = useState({
        visible: false,
        status: NotificationStatus.info,
    });

    type AllowedOptions = {
        [key in ContactableOptions]: boolean;
    };

    interface CustomerOptions {
        contactable: string;
        contactOptionsName: AllowedOptions;
    }
    const [tempOptions, setTempOptions] = useState<CustomerOptions>({} as CustomerOptions);
    const [visibleOptions, setVisibleOptions] = useState<CustomerOptions>({} as CustomerOptions);
    const [original, setOriginal] = useState<CustomerOptions>({} as CustomerOptions);

    const checkContactOption = (
        isContactable: boolean | undefined,
        optionInPreferences: boolean,
        option: ContactableOptions,
    ) => {
        if (isContactable === undefined) {
            setTempOptions(temp => ({
                ...temp,
                contactable: '-',
                contactOptionsName: {
                    ...temp.contactOptionsName,
                    [option]: true,
                },
            }));

            setOriginal(original => ({
                ...original,
                contactable: '-',
                contactOptionsName: {
                    ...original.contactOptionsName,
                    [option]: false,
                },
            }));
        } else {
            if (!isContactable) {
                setTempOptions(temp => ({
                    ...temp,
                    contactable: 'no',
                    contactOptionsName: {
                        ...temp.contactOptionsName,
                        [option]: true,
                    },
                }));

                setOriginal(original => ({
                    ...original,
                    contactable: 'no',
                    contactOptionsName: {
                        ...original.contactOptionsName,
                        [option]: false,
                    },
                }));
            } else {
                if (optionInPreferences) {
                    setTempOptions(temp => ({
                        ...temp,
                        contactable: 'yes',
                        contactOptionsName: {
                            ...temp.contactOptionsName,
                            [option]: true,
                        },
                    }));
                    setOriginal(original => ({
                        ...original,
                        contactable: 'yes',
                        contactOptionsName: {
                            ...original.contactOptionsName,
                            [option]: true,
                        },
                    }));
                } else {
                    setTempOptions(temp => ({
                        ...temp,
                        contactable: 'yes',
                        contactOptionsName: {
                            ...temp.contactOptionsName,
                            [option]: false,
                        },
                    }));
                    setOriginal(original => ({
                        ...original,
                        contactable: 'yes',
                        contactOptionsName: {
                            ...original.contactOptionsName,
                            [option]: false,
                        },
                    }));
                }
            }
        }
    };

    const renderOptions = () => {
        const allowIteration = visibleOptions && visibleOptions.contactOptionsName;

        return (
            allowIteration &&
            Object.keys(visibleOptions.contactOptionsName).map((option, index) => {
                return (
                    <Layout.Item key={index} default={'1/4'} m={'1/1'} s="1/1" xs="1/1" className={'u-p-none'}>
                        <ValidatedCheckboxGroup.Checkbox
                            className={'u-mb-small'}
                            label={t(`options.${option}`)}
                            groupName={'contactOptionsName'}
                            name={`contactOptionsName.${option}`}
                            disabled={!allowEdit}
                        />
                    </Layout.Item>
                );
            })
        );
    };

    const onHandleSubmit = (values: CustomerOptions) => {
        const array = Object.keys(values.contactOptionsName);
        const selectedOptions: ContactableOptions[] = [];
        array.forEach(key => {
            const k = key as ContactableOptions;
            if (values.contactOptionsName[k]) {
                selectedOptions.push(k);
            }
        });
        if (!selectedOptions.length && values.contactable === 'yes') {
            setResponseNotification({ status: NotificationStatus.warning, visible: true });
        } else {
            let payload = {};

            if (values.contactable === 'no') {
                payload = {
                    id: contact?.id,
                    contactable: false,
                    requestDate: moment().format('DD/MM/YYYY HH:mm:ss'),
                    contactableOptions: [],
                };
            } else {
                payload = {
                    id: contact?.id,
                    contactable: true,
                    requestDate: moment().format('DD/MM/YYYY HH:mm:ss'),
                    contactableOptions: selectedOptions,
                };
            }
            setSendingRequest(true);

            CpDataApi.put(getMktPreferencesEndpoint(), payload)
                .then(() => {
                    setResponseNotification({ status: NotificationStatus.success, visible: true });
                    setSendingRequest(false);
                    setAllowEdit(false);
                    CpDataApi.get(getMktPreferencesEndpoint()).then(response => {
                        updateMyMktPreferences(response.data);
                    });
                    onSuccess(trackingSectionType, values);
                    onChanged(values, contact, trackingSectionType, 'Profile');
                })
                .catch(() => {
                    setResponseNotification({ status: NotificationStatus.error, visible: true });
                    setSendingRequest(false);
                    setAllowEdit(true);
                    onError(trackingSectionType);
                });
        }
    };

    const handleOnClose = () => {
        setResponseNotification(state => ({ ...state, visible: false }));
    };

    const responseMessage = () => {
        const status = responseNotification.status;
        return (
            <Notification
                showCloseButton
                visible={responseNotification.visible}
                onCloseClick={() => handleOnClose()}
                status={status}
                className="u-mv"
                text={t(`messages.${status}`)}
                testId={`notification-${status}`}
            />
        );
    };

    useEffect(() => {
        if (contact && (contact.phoneNumber || contact.cellphone || contact.email)) {
            const customerContactableOptions = mktPreferences?.contactableOptions || [];
            const isContactable = mktPreferences?.contactable;
            const { phoneNumber, cellphone, email } = contact;

            const listOptions = [ContactableOptions.EMAIL, ContactableOptions.SMS, ContactableOptions.PHONE];

            listOptions.forEach(option => {
                const optionContact = option as ContactableOptions;
                const optionInPreferences = customerContactableOptions.includes(optionContact);
                if ((optionContact === ContactableOptions.SMS || ContactableOptions.WHATSAPP) && cellphone) {
                    checkContactOption(isContactable, optionInPreferences, optionContact);
                } else if (optionContact === ContactableOptions.EMAIL && email) {
                    checkContactOption(isContactable, optionInPreferences, optionContact);
                } else if (optionContact === ContactableOptions.PHONE && phoneNumber) {
                    checkContactOption(isContactable, optionInPreferences, optionContact);
                }
            });
        }
    }, [contact, mktPreferences]);

    useEffect(() => {
        if (mktPreferences?.contactable) {
            if (Object.keys(original).length) {
                setVisibleOptions(original);
            }
        } else {
            if (Object.keys(tempOptions).length) {
                setVisibleOptions(tempOptions);
            }
        }
    }, [original, tempOptions, mktPreferences]);

    return (
        <div>
            <div>
                <Layout.Item default={'1/2'} m={'1/1'} s="1/1" xs="1/1" className={'u-p-none'}>
                    <h4 className="c-data-overview__title">{t('headline')}</h4>
                    <p>{t('subHeadline')}</p>
                </Layout.Item>
                <Layout.Item default={'1/2'} m={'1/1'} s="1/1" xs="1/1" className={'u-p-none'}>
                    <div className="u-text-right">
                        <Button secondary testId={'btn-edit-mkt'} onClick={() => setAllowEdit(!allowEdit)}>
                            {!allowEdit ? t('buttons.edit') : 'x'}
                        </Button>
                    </div>
                </Layout.Item>
            </div>
            <div className="u-mv">
                <b className={`${!allowEdit && 'u-text-muted'}`}>{t('question.contactable')}</b>
            </div>
            <div>
                <Formik
                    enableReinitialize
                    initialValues={visibleOptions}
                    onSubmit={(values): void => {
                        onHandleSubmit(values);
                    }}
                >
                    {formik => (
                        <>
                            <RadioButtonGroup>
                                <Field
                                    as={RadioButton}
                                    disabled={!allowEdit}
                                    type="radio"
                                    name="contactable"
                                    value="yes"
                                >
                                    Sí
                                </Field>
                                <Field
                                    as={RadioButton}
                                    disabled={!allowEdit}
                                    type="radio"
                                    name="contactable"
                                    value="no"
                                >
                                    No
                                </Field>
                            </RadioButtonGroup>
                            {formik.values.contactable === 'yes' && (
                                <>
                                    <div className="u-mv">
                                        <b className={`${!allowEdit && 'u-text-muted'}`}>{t('question.options')}</b>
                                    </div>
                                    <ValidatedCheckboxGroup
                                        className={'some-class'}
                                        label=""
                                        name={'contactOptionsName'}
                                        isMandatory={true}
                                    >
                                        {renderOptions()}
                                    </ValidatedCheckboxGroup>
                                    <p className={`${!allowEdit && 'u-text-muted'}`}>
                                        <sup>{t('subscript')}</sup>
                                        <span className="u-font-size-fs-1 u-pt-xsmall">{t('legal')}</span>
                                    </p>
                                </>
                            )}
                            {sendingRequest ? (
                                <Spinner small center />
                            ) : (
                                <>
                                    {responseMessage()}
                                    {allowEdit && (
                                        <ButtonContainer center>
                                            <Button
                                                secondary
                                                testId={'dashboard-button'}
                                                onClick={() => {
                                                    setVisibleOptions(original);
                                                    formik.handleReset();
                                                    setAllowEdit(false);
                                                }}
                                            >
                                                {t('buttons.cancel')}
                                            </Button>
                                            <Button
                                                testId={'submit-button'}
                                                type="submit"
                                                disabled={formik.values.contactable === '-'}
                                                onClick={() => formik.handleSubmit()}
                                            >
                                                {t('buttons.submit')}
                                            </Button>
                                        </ButtonContainer>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </Formik>
            </div>
        </div>
    );
};
