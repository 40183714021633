import React from 'react';

import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';

import { DashboardMarketingCardUi } from './ui';
import { useDashboardMarketingCard } from './useDashboardMarketingCard';
import { useContract } from '../contracts/useContract';
import { NO_WARRANTY_MODELS, WARRANTY_BRANDS } from '../../conts';

const DashboardMarketingCardWithHandlers = withLoadingAndNoConnectionHandler(DashboardMarketingCardUi);

export const DashboardMarketingCard: React.FC<{ content?: string }> = ({ content }) => {
    const { cmsContent: DashboardMarketingCard, isLoading, loadingError } = useDashboardMarketingCard();
    const { data } = useContract();
    if (content === 'warranty' && !data?.find(contract => WARRANTY_BRANDS.includes(contract.vehicle.brand))) {
        return null;
    }
    if (content === 'warranty' && data?.find(contract => NO_WARRANTY_MODELS.includes(contract.vehicle.model))) {
        return null;
    }
    return (
        <>
            <DashboardMarketingCardWithHandlers
                isLoading={isLoading}
                dashboardMarketingCard={DashboardMarketingCard}
                content={content}
                hasError={!!loadingError}
            />
            <br />
        </>
    );
};
