import React, { useState, useEffect, useCallback } from 'react';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
    ContactData,
    getContactDataEndpoint,
    TypeOfPerson,
    getValidateOtpEndpoint,
    getGenerateOtpEndpoint,
    ContactType,
} from '@cp-mx/common';
import { Formik, useField } from 'formik';
import { Notification, NotificationStatus, Spinner } from '@cp-shared-8/frontend-ui';
import { Button, ErrorMessage, InlineEditable, Layout, Modal } from '@vwfs-bronson/bronson-react';
import { CpDataApi } from 'cp-xhr';
import { useMyProfile } from '../useMyProfile';
import { useUpdateContactData } from '../useMyContactUpdate';
import { OtpInput } from './OtpInput';
import { Countdown } from './Countdown';

export const ContactList: React.FC<{ contact: ContactData }> = ({ contact }) => {
    const { t } = useTranslation('my-profile-contact-details');
    const { data: myProfile } = useMyProfile();
    const { updateContactData } = useUpdateContactData();

    const { phoneNumber = '', cellphone = '', email = '', customerExist, id = '' } = contact;
    const [responseNotification, setResponseNotification] = useState({
        visible: false,
        status: NotificationStatus.info,
    });

    const min = 10;
    const lengthCode = 6;
    const milliseconds = min * 60 * 1000;

    enum FieldType {
        Email = 'email',
        PhoneNumber = 'phoneNumber',
        Cellphone = 'cellphone',
    }

    type ModalOtpType = {
        field: FieldType;
        visible: boolean;
        fieldValue: string;
    };

    const [code, setCode] = useState<string>('');
    const [displayCounter, setDisplayCounter] = useState<number>(0);

    const [statusTimer, setStatusTimer] = useState<number>(milliseconds);
    const [incorrectCode, setIncorrectCode] = useState<boolean>(false);
    const [modal, setModal] = useState<ModalOtpType>({ field: FieldType.Email, visible: false, fieldValue: '' });
    const [sendingRequest, setSendingRequest] = useState<boolean>(false);
    const [sendingRequestPhoneNumber, setSendingRequestPhoneNumber] = useState<boolean>(false);

    const getEditButton = (field: FieldType): HTMLElement => {
        return document.querySelectorAll(`[data-id='${field}'] .c-inline-editable__btn`)[0] as HTMLElement;
    };

    const ContactField: React.FC<{
        fieldName: FieldType;
        submittedValue: string;
        onSubmit: (e?: React.FormEvent<HTMLFormElement>) => void;
        onReset: (e?: React.SyntheticEvent<EventTarget>) => void;
    }> = ({ fieldName, submittedValue, onSubmit, onReset }) => {
        const [field, meta] = useField(fieldName);

        return (
            <div data-id={fieldName}>
                <Layout.Item default={'1/4'} m={'1/2'} s="1/1" xs="1/1" className={'u-p-none'}>
                    <label>{t(`form.${fieldName}.label`)}</label>
                </Layout.Item>
                <Layout.Item default={'3/4'} m={'1/2'} s="1/1" xs="1/1" className={'u-p-none'}>
                    <dd>
                        <InlineEditable
                            valid={!meta.touched || !meta.error}
                            submittedValue={submittedValue}
                            onSubmit={() => onSubmit()}
                            onReset={() => {
                                onReset();
                                if (!submittedValue) {
                                    getEditButton(fieldName).click();
                                }
                            }}
                            error={(meta.touched && meta.error) as boolean}
                            size={12}
                            {...field}
                        />
                    </dd>
                </Layout.Item>
                {meta.touched && meta.error ? <ErrorMessage>{meta.error}</ErrorMessage> : null}
            </div>
        );
    };

    const formEmailValidation = Yup.object().shape({
        email: Yup.string()
            .email(t('form.email.error.format'))
            .required(t('form.email.error.required')),
    });

    const formPhoneNumberValidation = Yup.object().shape({
        phoneNumber: Yup.string()
            .matches(/^[0-9]{10}$/, t('form.phoneNumber.error.format'))
            .required(t('form.phoneNumber.error.required')),
    });

    const formCellphoneValidation = Yup.object().shape({
        cellphone: Yup.string()
            .matches(/^[0-9]{10}$/, t('form.cellphone.error.format'))
            .required(t('form.cellphone.error.required')),
    });

    const handleOnClose = () => {
        setResponseNotification(state => ({ ...state, visible: false }));
    };

    const responseMessage = () => {
        const status = responseNotification.status;
        return (
            <Notification
                showCloseButton
                visible={responseNotification.visible}
                onCloseClick={() => handleOnClose()}
                status={status}
                text={t(`response.${status}`)}
                testId={`notification-${status}`}
            />
        );
    };

    const getParams = (fieldType: FieldType) => {
        let channel = '';
        let key = '';
        if (fieldType === FieldType.Cellphone) {
            channel = ContactType.SMS;
            key = 'phoneNumber';
        } else {
            channel = ContactType.Email;
            key = 'email';
        }
        return { channel, key };
    };

    const sendOtp = (fieldType: FieldType, fieldValue: string) => {
        const { channel, key } = getParams(fieldType);

        CpDataApi.post(getGenerateOtpEndpoint(), {
            contactType: channel,
            [key]: fieldValue,
        }).catch(() => {
            setResponseNotification({ status: NotificationStatus.error, visible: true });
            setModal(() => ({ field: fieldType, visible: false, fieldValue }));
        });
    };

    const validateCode = (fieldType: FieldType, fieldValue: string) => {
        const { channel, key } = getParams(fieldType);

        setSendingRequest(true);
        CpDataApi.post(getValidateOtpEndpoint(), {
            contactType: channel,
            [key]: fieldValue,
            otpCode: code,
        })
            .then(() => {
                const typeOfPerson = myProfile?.isPhysicalPerson ? TypeOfPerson.PF : TypeOfPerson.PM;
                const requestDate = moment().format('DD/MM/YYYY HH:mm:ss');
                const payload = {
                    typeOfPerson,
                    requestDate,
                    [fieldType]: fieldValue,
                };

                if (customerExist) {
                    CpDataApi.put(getContactDataEndpoint(), { id, ...payload }).then(() =>
                        CpDataApi.get<ContactData>(getContactDataEndpoint()).then(response => {
                            setResponseNotification({ status: NotificationStatus.success, visible: true });
                            setModal(() => ({ field: fieldType, visible: false, fieldValue }));
                            setSendingRequest(false);
                            updateContactData(response.data);
                        }),
                    );
                } else {
                    CpDataApi.post(getContactDataEndpoint(), payload).then(() =>
                        CpDataApi.get<ContactData>(getContactDataEndpoint()).then(response => {
                            setResponseNotification({ status: NotificationStatus.success, visible: true });
                            setModal(() => ({ field: fieldType, visible: false, fieldValue }));
                            setSendingRequest(false);
                            updateContactData(response.data);
                        }),
                    );
                }
            })
            .catch(e => {
                if (e.response?.data?.code === 400) {
                    setIncorrectCode(true);
                } else {
                    setResponseNotification({ status: NotificationStatus.error, visible: true });
                    setModal(() => ({ field: fieldType, visible: false, fieldValue }));
                }

                setSendingRequest(false);
            });
    };

    const onHandleCode = (code: string) => {
        setCode(code);
    };

    const modalMessage = (fieldType: FieldType, fieldValue: string) => {
        let value = fieldValue;
        if (fieldType === FieldType.Cellphone) {
            value = fieldValue.slice(fieldValue.length - 4, fieldValue.length).padStart(10, '*');
        }
        return t(`modal.${fieldType}`).replace(`<${fieldType}>`, value);
    };

    const ExpiredMessage: React.FC = () => <div className={'c-error-message'}>{t('modal.errors.expired')}</div>;

    const ModalOtp = (shown: boolean, fieldType: FieldType, fieldValue: string) => {
        const label = modalMessage(fieldType, fieldValue);
        return (
            <Modal
                shown={shown}
                title={t('modal.title')}
                testId={'modal-otp'}
                onClose={() => {
                    setIncorrectCode(false);
                    setModal(modal => ({ ...modal, visible: false }));
                }}
            >
                <p>{label}</p>
                <div className="u-mv-small" style={{ background: 'white', display: 'inline-block' }}>
                    {shown && <OtpInput lengthCode={lengthCode} onHandleCode={onHandleCode} />}
                </div>
                <div className={`u-mb-small ${incorrectCode ? 'c-error-message' : ''}`}>
                    {shown && (
                        <Countdown
                            displayCounter={displayCounter}
                            milliseconds={milliseconds}
                            setStatusTimer={setStatusTimer}
                            component={<ExpiredMessage />}
                            incorrectCode={incorrectCode}
                        />
                    )}
                </div>
                <div>
                    {sendingRequest ? (
                        <Spinner small center />
                    ) : (
                        <>
                            <Button
                                testId={'dashboard-button'}
                                className="u-mh-small"
                                disabled={statusTimer !== 0}
                                onClick={() => {
                                    sendOtp(fieldType, fieldValue);
                                    setDisplayCounter(state => state + 1);
                                }}
                            >
                                {t(`modal.buttons.${fieldType}`)}
                            </Button>
                            <Button
                                testId={'submit-button'}
                                type="submit"
                                className="u-mh-small"
                                disabled={!(statusTimer && code.length === lengthCode)}
                                onClick={() => validateCode(fieldType, fieldValue)}
                            >
                                {t('modal.buttons.validate')}
                            </Button>
                        </>
                    )}
                </div>
            </Modal>
        );
    };
    const onHandleSubmit = async (field: FieldType, fieldValue: string) => {
        const typeOfPerson = myProfile?.isPhysicalPerson ? TypeOfPerson.PF : TypeOfPerson.PM;
        const requestDate = moment().format('DD/MM/YYYY HH:mm:ss');
        const payload = {
            typeOfPerson,
            requestDate,
            [field]: fieldValue,
        };
        if (!customerExist) {
            if (field === FieldType.PhoneNumber) {
                setSendingRequestPhoneNumber(true);
                CpDataApi.post(getContactDataEndpoint(), payload)
                    .then(() =>
                        CpDataApi.get<ContactData>(getContactDataEndpoint()).then(response => {
                            setResponseNotification({ status: NotificationStatus.success, visible: true });
                            setSendingRequestPhoneNumber(false);
                            updateContactData(response.data);
                        }),
                    )
                    .catch(() => {
                        setResponseNotification({ status: NotificationStatus.error, visible: true });
                        setSendingRequestPhoneNumber(false);
                    });
            } else {
                sendOtp(field, fieldValue);
                setModal({ field, visible: true, fieldValue });
            }
        } else {
            if (field === FieldType.PhoneNumber) {
                setSendingRequestPhoneNumber(true);
                CpDataApi.put(getContactDataEndpoint(), { id, ...payload })
                    .then(() =>
                        CpDataApi.get<ContactData>(getContactDataEndpoint()).then(response => {
                            setResponseNotification({ status: NotificationStatus.success, visible: true });
                            setSendingRequestPhoneNumber(false);
                            updateContactData(response.data);
                        }),
                    )
                    .catch(() => {
                        setSendingRequestPhoneNumber(false);
                        setResponseNotification({ status: NotificationStatus.error, visible: true });
                    });
            } else {
                sendOtp(field, fieldValue);
                setModal({ field, visible: true, fieldValue });
            }
        }
    };

    const openEmptyInput = useCallback(() => {
        if (!email) {
            getEditButton('email' as FieldType.Email).click();
        }
        if (!phoneNumber) {
            getEditButton('phoneNumber' as FieldType.PhoneNumber).click();
        }
        if (!cellphone) {
            getEditButton('cellphone' as FieldType.Cellphone).click();
        }
    }, [email, phoneNumber, cellphone]);

    useEffect(() => {
        openEmptyInput();
    }, [openEmptyInput, sendingRequest]);

    useEffect(() => {
        openEmptyInput();
    }, [openEmptyInput, modal]);

    useEffect(() => {
        openEmptyInput();
    }, [openEmptyInput, responseNotification]);

    return (
        <>
            <Layout.Item default={'1/2'} m={'1/1'} s="1/1" xs="1/1" className={'u-p-none'}>
                <Formik
                    initialValues={{ email }}
                    validationSchema={formEmailValidation}
                    onSubmit={values => {
                        onHandleSubmit(FieldType.Email, values.email);
                    }}
                >
                    {formik => (
                        <Layout.Item default={'1/1'} className={'u-p-none u-mb'}>
                            <ContactField
                                fieldName={FieldType.Email}
                                submittedValue={email}
                                onSubmit={formik.handleSubmit}
                                onReset={formik.handleReset}
                            />
                        </Layout.Item>
                    )}
                </Formik>
            </Layout.Item>
            <Layout.Item default={'1/2'} m={'1/1'} s="1/1" xs="1/1" className={'u-p-none'}>
                <Formik
                    initialValues={{ phoneNumber }}
                    validationSchema={formPhoneNumberValidation}
                    onSubmit={values => {
                        onHandleSubmit(FieldType.PhoneNumber, values.phoneNumber);
                    }}
                >
                    {formik => (
                        <Layout.Item default={'1/1'} className={'u-p-none u-mb'}>
                            <ContactField
                                fieldName={FieldType.PhoneNumber}
                                submittedValue={phoneNumber}
                                onSubmit={formik.handleSubmit}
                                onReset={formik.handleReset}
                            />
                        </Layout.Item>
                    )}
                </Formik>
            </Layout.Item>
            <Layout.Item default={'1/2'} m={'1/1'} s="1/1" xs="1/1" className={'u-p-none'}>
                <Formik
                    initialValues={{ cellphone }}
                    validationSchema={formCellphoneValidation}
                    onSubmit={values => {
                        onHandleSubmit(FieldType.Cellphone, values.cellphone);
                    }}
                >
                    {formik => (
                        <Layout.Item default={'1/1'} className={'u-p-none u-mb'}>
                            <ContactField
                                fieldName={FieldType.Cellphone}
                                submittedValue={cellphone}
                                onSubmit={formik.handleSubmit}
                                onReset={formik.handleReset}
                            />
                        </Layout.Item>
                    )}
                </Formik>
            </Layout.Item>
            {sendingRequestPhoneNumber && <Spinner small center />}
            {ModalOtp(modal.visible, modal.field, modal.fieldValue)}
            {responseMessage()}
        </>
    );
};
