import { Spinner } from '@cp-shared-8/frontend-ui';
import React from 'react';

/**
 * View responsible for providing the suspense view
 */
export const SuspensePage: React.FC = () => {
    return (
        <div className="u-mv-xlarge">
            <Spinner center />
        </div>
    );
};
