import { DashboardMarketingCard, getDashboardMarketingCardEndpoint } from '@cp-mx/common';
import { createCmsContentSlice } from 'cms-integration';

const { reducer, fetchContent } = createCmsContentSlice<DashboardMarketingCard>({
    contentName: 'DashboardMarketingCard',
    contentEndpoint: getDashboardMarketingCardEndpoint,
});

export default reducer;
export const fetchDashboardMarketingCard = fetchContent;
