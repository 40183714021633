export function notFoundPagePath(): string {
    return '/not-found';
}

export function registrationPagePath(): string {
    return '/register';
}

export function notAuthorizedPagePath(): string {
    return '/not-authorized';
}

export function dashboardPagePath(): string {
    return '/dashboard';
}

export function faqPagePath(): string {
    return '/faq';
}

export function cookiePolicyPath(): string {
    return '/cookie-policy';
}

export function legalNoticePath(): string {
    return '/legal-notice';
}

export function landingPagePath(): string {
    return '/';
}

export function myProfilePagePath(): string {
    return '/my-profile';
}

export function privacyPolicyPath(): string {
    return '/privacy-policy';
}

export function unpaidDetailsPath(): string {
    return '/unpaid-details';
}

export function openRequestPath(): string {
    return '/make-a-request';
}

export function changeContactDetailsPath(): string {
    return `${myProfilePagePath()}/change-contact-details`;
}

export function unauthorizedPagePath(): string {
    return '/unauthorized';
}

export function renewalPath(): string {
    return '/renewal';
}
