import { NotificationCloseSelector } from './NotificationCloseSelector';
import { useDispatch, useSelector } from 'react-redux';

export const useRenewalNotificationClose = () => {
    const dispatch = useDispatch();
    const notificationCloseState = useSelector(NotificationCloseSelector);

    return {
        notificationCloseState,
        setNotification: (contractNumber: string) => {
            dispatch({ type: 'add', data: [...notificationCloseState, contractNumber] });
        },
        deleteNotification: (contractNumber: string) => {
            dispatch({
                type: 'delete',
                data: notificationCloseState.find(notification => notification !== contractNumber) || [],
            });
        },
    };
};
