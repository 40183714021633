import React, { useState } from 'react';
import {
    ButtonContainer,
    Button,
    ContentSection,
    Heading,
    Modal,
    PageWrap,
    TileSelect,
    TileSelectGroup,
} from '@vwfs-bronson/bronson-react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
    getRenewalChoiceEndpoint,
    RenewalChoice,
    RenewalOption,
    RenewalContract,
    NotificationsCenter,
    getNotificationsByCustomerEndpoint,
    updateRenewalCloseEndpoint,
    postSendEmailRenewalChoiceEndpoint,
    NotificationCenter,
    getNotificationsCenterEndpoint,
} from '@cp-mx/common';
import { useHistory } from 'react-router-dom';
import { dashboardPagePath } from 'components/navigation/paths';
import { useUpdateNotificationCenter } from 'components/notification-center/useUpdateNotificationCenter';
import { useBrandContent } from 'components/contracts';
import { CpDataApi } from 'cp-xhr';
import { Spinner, useAnalyticsActionTracker } from '@cp-shared-8/frontend-ui';
import { CtxNotification } from 'components/notifications/context-notification/ContextNotification';
import { renewalFlowState } from 'components/renewal-notification/ui';
import { useUpdateNotificationCenterComponent } from 'components/notification-center/useUpdateNotificationCenterComponent';
import { useRenewalNotificationClose } from 'components/renewal-notification/useNotificationClose';

const ModalMessage: React.FC<{
    shown: boolean;
    onConfirm: () => void;
}> = ({ shown, onConfirm }) => {
    const { t } = useTranslation('renewal-modal');

    return (
        <Modal
            hideCloseButton
            shown={shown}
            title={t('title')}
            onConfirm={() => onConfirm()}
            buttonConfirmText={t('confirm')}
            testId={'confirm-modal'}
        >
            {t('body')}
        </Modal>
    );
};

export const RenewalOptionsUi: React.FC<{
    renewalOptions?: RenewalOption[];
    renewalContractSelected: RenewalContract;
}> = ({ renewalOptions, renewalContractSelected }) => {
    const { t } = useTranslation('renewal-options');

    const history = useHistory();

    const { updateNotificationCenter } = useUpdateNotificationCenter();

    const { updateNotificationCenterComponent } = useUpdateNotificationCenterComponent();

    const { deleteNotification } = useRenewalNotificationClose();

    const { cmsContent: brandCatalog } = useBrandContent();

    const backDashboard = () => history.push(dashboardPagePath());

    const [shownModal, setShownModal] = useState(false);
    const [shownSpinner, setSpinner] = useState(false);
    const [hasError, setHasError] = useState(false);

    const [disableButton, setDisableButton] = useState(true);

    const [selection, setSelection] = useState({} as RenewalChoice);
    const { onAction: onClickRenewalCard } = useAnalyticsActionTracker('digitalRenewal');

    const handleClick = () => backDashboard();

    const handleOnConfirm = async () => {
        setSpinner(true);
        try {
            const sendEmailPayload = {
                sendTo: selection.emailReceipt,
                contractId: selection.contractLabel,
                product: selection.financeProduct,
                optionTitle: selection.optionTitle,
            };

            if (selection.optionId !== 'credit-finish-contract' && selection.optionId !== 'premium-credit-pay-off') {
                await CpDataApi.post<string>(postSendEmailRenewalChoiceEndpoint(), sendEmailPayload);
            }

            await CpDataApi.post(getRenewalChoiceEndpoint(), selection);

            await CpDataApi.patch(updateRenewalCloseEndpoint(), {
                contractNumber: renewalContractSelected.contractNumber,
                flowState: renewalFlowState.OptionSelected,
            });
            const newDataNotification = await CpDataApi.get<NotificationsCenter[]>(
                getNotificationsByCustomerEndpoint(),
            ).then(response => response.data);
            updateNotificationCenter(newDataNotification);
            const newDataNotificationCenter = await CpDataApi.get<NotificationCenter[]>(
                getNotificationsCenterEndpoint(),
            ).then(response => response.data);
            await updateNotificationCenterComponent(newDataNotificationCenter);
            deleteNotification(renewalContractSelected.contractNumber);
            setSpinner(false);
            setShownModal(true);
        } catch (e) {
            console.log(e);
            setHasError(true);
            setSpinner(false);
        }
    };

    const brandName =
        brandCatalog && brandCatalog[renewalContractSelected.brand]
            ? brandCatalog[renewalContractSelected.brand]
            : renewalContractSelected.brand;

    const replaceTextHeadLine = () => {
        return t('headline').replace('<brand>', brandName);
    };

    const onHandleClickOption = (option: RenewalOption) => {
        const date = moment().format('DD/MM/YYYY');
        const data = {
            emailReceipt: option.email,
            optionTitle: option.title,
            optionId: option.id,
            ...renewalContractSelected,
            date,
            brand: brandName,
        };
        setSelection(data);
        setDisableButton(false);
    };

    if (!renewalOptions?.length) {
        return null;
    }

    return (
        <PageWrap>
            <ContentSection pageWrapSize="medium">
                <Heading level={1} className="u-mb-large">
                    {replaceTextHeadLine()}
                </Heading>
                <h3>{t('subHeadline')}</h3>
                <p>{t('body')}</p>
                <TileSelectGroup
                    key={'group-renewal-options'}
                    layoutItemClassName="u-1/3 u-1/2@l u-1/1@s"
                    className={'u-text-center'}
                    testId={'tile-select-group-main'}
                >
                    {renewalOptions.map((option, index) => {
                        return (
                            <TileSelect
                                key={index}
                                icon={option.icon}
                                title={option.title}
                                inputType="radio"
                                radioGroupName="renewal-item"
                                onClick={() => onHandleClickOption(option)}
                            >
                                <div dangerouslySetInnerHTML={{ __html: option.description }} />
                            </TileSelect>
                        );
                    })}
                </TileSelectGroup>
            </ContentSection>
            <ButtonContainer center>
                <Button secondary testId={'dashboard-button'} onClick={() => handleClick()}>
                    {t('buttons.back')}
                </Button>
                <Button
                    testId={'submit-button'}
                    type="submit"
                    disabled={disableButton}
                    onClick={() => {
                        onClickRenewalCard(selection.optionTitle);
                        handleOnConfirm();
                    }}
                >
                    {t('buttons.submit')}
                </Button>
            </ButtonContainer>
            {hasError && (
                <ContentSection>
                    <CtxNotification
                        testId="renewal-error"
                        component="renewal-options"
                        visible={hasError}
                        onCloseClick={() => setHasError(false)}
                    />
                </ContentSection>
            )}
            {shownSpinner && <Spinner fullPage />}
            <ModalMessage
                shown={shownModal}
                onConfirm={() => {
                    setShownModal(false);
                    backDashboard();
                }}
            />
        </PageWrap>
    );
};
