import React from 'react';

import { Contract, FinancialDetails, formatAsCurrency, formatAsDate } from '@cp-mx/common';
import { DefinitionListHorizontal, DefinitionListItem } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { ErrorNotification } from 'components/notifications/error/ErrorNotification';
import { useProductNamesContent } from '../useFinancialDetails';
import { Tag } from '@vwfs-bronson/bronson-react';
import { validateLeasingContract, validatePremiumCreditContract } from 'components/contracts/utils';

export const FinancialDetailsUi: React.FC<{ contract?: Contract; financialDetails?: FinancialDetails }> = ({
    financialDetails,
    contract,
}) => {
    const { t } = useTranslation('financial-details');
    const { cmsContent: productNames } = useProductNamesContent();

    if (!contract || !financialDetails) {
        return (
            <ErrorNotification testId={'no-connection-error'} headline={t('error.headline')} text={t('error.text')} />
        );
    }

    const validateballoonPayment = (contract: Contract, financialDetails: FinancialDetails): DefinitionListItem => {
        if (financialDetails.balloonPayment !== undefined) {
            if (
                productNames &&
                productNames.names.some((name: string) => contract.product.name.toLocaleUpperCase().includes(name))
            ) {
                return {
                    label: t('balloonPayment'),
                    value: formatAsCurrency(financialDetails.balloonPayment),
                    testId: 'balloonPayment',
                    tooltip: t('balloonPaymentTooltip'),
                };
            } else
                return {
                    label: t('balloonPaymentFinal'),
                    value: formatAsCurrency(financialDetails.balloonPayment),
                    testId: 'balloonPayment',
                };
        } else {
            return {
                label: t('balloonPaymentFinal'),
                value: <Tag error={true}> {t('errorValue')}</Tag>,
                testId: 'balloonPaymentFinal',
                tooltip: t('balloonPaymentFinalTooltip'),
            };
        }
    };

    const getListItem = (contract: Contract, financialDetails: FinancialDetails): DefinitionListItem[] => {
        const items: DefinitionListItem[] = [];
        if (financialDetails.financedAmount && !validateLeasingContract(contract.product.group)) {
            items.push({
                label: t('financedAmount'),
                value: formatAsCurrency(financialDetails.financedAmount),
                testId: 'financedAmount',
                tooltip: t('financedAmountTooltip'),
            });
        }
        if (financialDetails.monthlyDueDay) {
            items.push({
                label: t('monthlyDueDay'),
                value: financialDetails.monthlyDueDay,
                testId: 'monthlyDueDay',
                tooltip: t('monthlyDueDayTooltip'),
            });
        } else {
            items.push({
                label: t('monthlyDueDay'),
                value: <Tag error={true}> {t('errorValue')}</Tag>,
                testId: 'monthlyDueDay',
                tooltip: t('monthlyDueDayTooltip'),
            });
        }
        if (financialDetails.nextIrregularPaymentDate && !validateLeasingContract(contract.product.group)) {
            items.push({
                label: t('nextIrregularPaymentDate'),
                value: formatAsDate(financialDetails.nextIrregularPaymentDate),
                testId: 'nextIrregularPaymentDate',
                tooltip: t('nextIrregularPaymentDateTooltip'),
            });
        }
        if (financialDetails.nextIrregularPaymentAmount) {
            items.push({
                label: t('nextIrregularPaymentAmount'),
                value: formatAsCurrency(financialDetails.nextIrregularPaymentAmount),
                testId: 'nextIrregularPaymentAmount',
                tooltip: t('nextIrregularPaymentAmountTooltip'),
            });
        }
        if (validatePremiumCreditContract(contract.product.group)) {
            items.push(validateballoonPayment(contract, financialDetails));
        }
        if (financialDetails.startDate) {
            items.push({
                label: t('startDate'),
                value: formatAsDate(financialDetails.startDate),
                testId: 'startDate',
                tooltip: t('startDateTooltip'),
            });
        }
        if (financialDetails.endDate) {
            items.push({
                label: t('endDate'),
                value: formatAsDate(financialDetails.endDate),
                testId: 'startDate',
                tooltip: t('endDateTooltip'),
            });
        }
        if (financialDetails.term) {
            items.push({
                label: t('term'),
                value: financialDetails.term.concat(' meses'),
                testId: 'term',
                tooltip: t('termTooltip'),
            });
        }
        if (financialDetails.earlyInstalmentsAmount && validateLeasingContract(contract.product.group)) {
            items.push({
                label: t('earlyInstalmentsAmount'),
                value: formatAsCurrency(financialDetails.earlyInstalmentsAmount),
                testId: 'earlyInstalmentsAmount',
                tooltip: t('earlyInstalmentsAmountTooltip'),
            });
        }
        if (validateLeasingContract(contract.product.group)) {
            if (financialDetails.defermentPaymentDays) {
                items.push({
                    label: t('defermentPaymentDays'),
                    value: String(financialDetails.defermentPaymentDays),
                    testId: 'defermentPaymentDays',
                    tooltip: t('defermentPaymentDaysTooltip'),
                });
            } else {
                items.push({
                    label: t('defermentPaymentDays'),
                    value: <Tag error={true}> {t('errorValue')}</Tag>,
                    testId: 'defermentPaymentDays',
                    tooltip: t('defermentPaymentDaysTooltip'),
                });
            }
        }
        return items;
    };

    return <DefinitionListHorizontal list={getListItem(contract, financialDetails)} />;
};
