import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { CookiePolicyUi } from './ui';
import { useCookiePolicy } from './useCookiePolicy';
import { useAnalyticsPageViewTracker } from '@cp-shared-8/frontend-ui';

const CookiePolicyWithHandlers = withLoadingAndNoConnectionHandler(CookiePolicyUi);

export const CookiePolicy: React.FC = () => {
    const { cmsContent: cookiePolicy, isLoading, loadingError } = useCookiePolicy();
    useAnalyticsPageViewTracker('cookiePolicy', !!cookiePolicy);

    return <CookiePolicyWithHandlers isLoading={isLoading} cookiePolicy={cookiePolicy} hasError={!!loadingError} />;
};
