import { UserRegistryStatus } from '@cp-mx/common';
import { AuthorizationResult } from '@cp-shared-8/frontend-ui';
import { useDispatch, useSelector } from 'react-redux';
import { AuthorizationContentSelector } from './AuthorizationContentSelector';

export const useAuthorization = () => {
    const dispatch = useDispatch();
    const authorization = useSelector(AuthorizationContentSelector);

    return {
        isRegistered: authorization.isRegistered,
        isAuthorized: authorization.isAuthorized,
        setAuthorization: (authorizationResult: AuthorizationResult<UserRegistryStatus>) => {
            dispatch({ type: 'Authorization', authorizationResult });
        },
    };
};
