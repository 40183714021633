import React from 'react';
import { withLoadingAndNoConnectionHandler } from 'components/integration-wrapper';
import { Contract } from '@cp-mx/common';
import { VehicleDetailsUi } from './ui/VehicleDetailsUi';
import { useVehicleDetails } from './useVehicleDetails';

const VehicleDetailsWithHandlers = withLoadingAndNoConnectionHandler(VehicleDetailsUi);

export const VehicleDetails: React.FC<{ contract: Contract }> = ({ contract }) => {
    const { data: vehicleDetails, isLoading, loadingError } = useVehicleDetails(contract.contractNumber);

    return (
        <VehicleDetailsWithHandlers
            isLoading={isLoading}
            vehicleDetails={vehicleDetails}
            contract={contract}
            hasError={!!loadingError}
            component="vehicle-details"
        />
    );
};
