import { Contract } from '@cp-mx/common';
import { DefaultMarketApiErrors } from '@cp-shared-8/common-utilities';
import { AbstractDataState, useGetSimpleApiDataWithTransformer } from '@cp-shared-8/frontend-integration';
import { useMemo } from 'react';
import { ContractContentSelector, fetchContracts } from '.';

type ContractTransformer = (contracts?: Contract[]) => Contract | undefined;

function getFilterForContractId(contractId: string): ContractTransformer {
    return contracts =>
        contracts ? contracts.filter(contract => contract.contractNumber === contractId)[0] : undefined;
}

/**
 * Fetches the contracts and filters for the contract with given contract id. If not found, undefined will be returned as data.
 * @param contractId Contract ID to get the contract for.
 */
export function useContractEspecific(
    contractId: string,
): AbstractDataState<Contract | undefined, DefaultMarketApiErrors> {
    const contractFilter = useMemo(() => getFilterForContractId(contractId), [contractId]);
    return useGetSimpleApiDataWithTransformer(fetchContracts, ContractContentSelector, contractFilter);
}
