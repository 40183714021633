export enum ContactableOptions {
    EMAIL = 'email',
    PHONE = 'phone',
    SMS = 'sms',
    WHATSAPP = 'whatsapp',
}

export type MktPreferences = {
    contactable?: boolean;
    contactableOptions?: ContactableOptions[];
};

export type MktPreferencesResponse = MktPreferences & {
    customerExist: boolean;
    id?: string;
};

export type UpdateMktPreferences = MktPreferences & {
    requestDate: string;
    interactions?: Array<string>;
};

export type UpdateContactCustomerRequest = UpdateMktPreferences & {
    id: string;
};
