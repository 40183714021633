import { CPDate } from '@cp-shared-8/common-utilities';

export type PersonRegistrationData = {
    idCustomer: string;
    dateOfBirth: CPDate;
};

export type BusinessRegistrationData = {
    idCustomer: string;
    dateFounded: CPDate;
};

export type RegistrationData = {
    idCustomer: string;
};

export type RegistrationResult = {
    idCustomer?: string;
    message?: string;
};

export interface UserRegistrationResult {
    /*
     * An identifier whether user is registered in CustomerPortal or not
     */
    isRegistered: boolean;
}

export enum RegistrationError {
    ALREADY_REGISTERED = 'ALREADY_REGISTERED',
    VALIDATION_ERROR = 'VALIDATION_ERROR',
    FORBIDDEN = 'FORBIDDEN',
    CUSTOMER_NOT_FOUND = 'CUSTOMER_NOT_FOUND',
    ONLY_VWL = 'ONLY_VWL',
    NETWORK_ERROR = 'NETWORK_ERROR',
}
