import React from 'react';
import { Address } from '@cp-mx/common';
import { DataOverview } from '@cp-shared-8/frontend-ui';
import { useTranslation } from 'react-i18next';
import { AddressList } from './AddressList';
import { addressValuesAreEmpty } from './utils';

export const AddressSection: React.FC<{ address?: Address }> = ({ address }) => {
    const { t } = useTranslation('my-profile-addresses-details');

    if (!address || addressValuesAreEmpty(address)) {
        return null;
    }

    return (
        <DataOverview title={t('headline')}>
            <AddressList address={address} />
        </DataOverview>
    );
};
