import React, { useRef } from 'react';
import { Redirect, RouteProps, useHistory } from 'react-router-dom';
import { Authentication } from '../authentication';
import { CpDataApi } from '../../cp-xhr';
import { getRegistrationEndpoint, getUserRegistryStatusEndpoint, UserRegistryStatus } from '@cp-mx/common';
import { landingPagePath, registrationPagePath, unauthorizedPagePath } from '../../components/navigation/paths';
import { AuthorizationResult, createAuthorization, createDecoratedRoute, Spinner } from '@cp-shared-8/frontend-ui';
import { useLogout } from 'auth/useLogout';
import { useAuthorization } from './useAuthorization';

/**
 * Component responsible for the authorized routes and state handling
 *
 * @param props { RouteProps }
 * @returns
 */
export const AuthorizedRoute: React.FC<RouteProps> = props => {
    const Route = useRef<React.FC<RouteProps>>();
    const history = useHistory();
    const logout = useLogout(unauthorizedPagePath);
    const { setAuthorization } = useAuthorization();

    const authDataProvider = async (): Promise<AuthorizationResult<UserRegistryStatus>> => {
        return CpDataApi.get<UserRegistryStatus>(getUserRegistryStatusEndpoint())
            .then(({ data: registrationData }) =>
                registrationData.isRegistered
                    ? CpDataApi.post<UserRegistryStatus>(getRegistrationEndpoint())
                          .then(() => ({ isAuthorized: true, isRegistered: registrationData.isRegistered }))
                          .catch(() => ({ isAuthorized: false, isRegistered: registrationData.isRegistered }))
                    : { isAuthorized: false, isRegistered: registrationData.isRegistered },
            )
            .then(userRegistryStatus => {
                !userRegistryStatus.isRegistered && history.push(registrationPagePath());
                userRegistryStatus.isRegistered && !userRegistryStatus.isAuthorized && logout();

                setAuthorization(userRegistryStatus);
                return userRegistryStatus;
            });
    };

    const { Authorization: UserRegistrationAuthorization } = createAuthorization({
        displayName: 'UserRegistrationAuthorization',
        authorizationDataProvider: authDataProvider,
        onMissingAuthorization: <Spinner center />,
        onLoading: <Spinner center />,
        onError: <Redirect to={landingPagePath()} />,
    });

    !Route.current &&
        (Route.current = createDecoratedRoute('AuthorizedRoute', Authentication, UserRegistrationAuthorization));

    return Route.current && <Route.current {...props}>{props.children}</Route.current>;
};
