export function getContractsOverviewEndpoint(): string {
    return '/contracts';
}
export function getFinancialDetailsEndpoint(contractId: string): string {
    return `${getContractsOverviewEndpoint()}/${contractId}/financialDetails/`;
}

export function getVehicleDetailsEndpoint(contractId: string): string {
    return `${getContractsOverviewEndpoint()}/${contractId}/vehicles`;
}
